import React from "react"
import View from "./view"

type Props = {
  checkoutComplete?: boolean
}

const CartDetailsB2B = ({ checkoutComplete }: Props) => {
  return <View checkoutComplete={checkoutComplete} />
}

export default CartDetailsB2B
