import { Box, Typography } from "@mui/material"
import styled from "styled-components"
import theme, { colors } from "../../styles/theme"

export const CheckoutBox = styled(Box)`
  background-color: #ffffff;
  max-width: 870px;
  width: 100%;
  height: 100%;
`

export const CheckoutHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
  border-bottom: 0.5px solid ${colors.gray};

  && h3 {
    font-size: 25px;
  }

  ${theme.breakpoints.down("sm")} {
    height: 64px;
    padding: 0 20px;

    && h3 {
      font-size: 20px;
    }
  }
`

export const FormBox = styled(Box)`
  padding: 32px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 24px 20px;
  }
`

export const CardInformationLabel = styled(Typography)`
  font-size: 20px !important;
  display: flex;
  align-items: center;
  margin-top: -11px !important;
  margin-bottom: -11px !important;
  padding-bottom: 16px !important;

  && img {
    margin-left: 14px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 18px !important;
    margin-top: -5px !important;
    margin-bottom: -4px !important;
    padding-bottom: 20px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
`

export const BillingDetailsLabel = styled(Typography)`
  font-size: 20px !important;
  margin-top: -11px !important;
  margin-bottom: -10px !important;
  padding-top: 32px;
  padding-bottom: 16px;

  ${theme.breakpoints.down("sm")} {
    font-size: 18px !important;
    margin: 0 !important;
    padding-bottom: 20px;
  }
`

export const BorderedBox = styled(Box)<{ boxPosition: number }>`
  border: 0.5px solid ${colors.gray};
  padding: 32px 24px 32px;

  && .formRowSpacing:not(:first-child) {
    margin-top: 46px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    border: 0;

    && .formRowSpacing {
      margin-top: 30px;
    }
  }
`

export const FormRow = styled(Box)`
  display: flex;
  gap: 16px;

  && .selectInputField > div {
    padding: 0 32px 0 13px !important;
  }

  ${theme.breakpoints.down("sm")} {
    gap 0px;
    flex-flow: wrap;

    && > div:nth-child(2) {
      margin-top: 46px;
    }
  }
`

export const BlueBox = styled(Box)`
  margin-top: 32px;

  && p {
    margin-top: -4px;
    margin-bottom: -1px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 20px;

    && p {
      font-size: 14px;
    }
  }
`

export const BlueLabel = styled.a`
  color: ${colors.lightBlue};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`

export const ButtonBox = styled(Box)`
  padding: 32px;
  border-top: 0.5px solid ${colors.gray};
  box-sizing: border-box;
  display: flex;
  gap: 16px;

  && .formSubmitButton:hover {
    background: ${colors.black} !important;
    color: white;
  }

  && .formSubmitButton:hover * {
    border: none !important;
  }

  && .formCancelButton:hover {
    color: ${colors.alert};
    border: 1px solid ${colors.alert} !important;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px;
    flex-flow: column;
  }
`

export const CheckboxBox = styled(Box)`
  padding-top: 24px;
  ${theme.breakpoints.down("sm")} {
    padding-top: 20px;
  }
`
