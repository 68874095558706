import React, { useState } from "react"
import {
  ModalContainer,
  FormContainer,
  FormContent,
  Logo,
  CloseButton,
} from "./styles"
import LoginForm from "../LoginForm"
import CreateForm from "../CreateForm"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import CloseBox from "../../images/icons/CloseBox.png"
import IndividualAccountForm from "../IndividualAccountForm"

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
  formPageNumber?: number
  updateFormPageNumber?: (num: number) => void
  navbarLogin?: boolean
}

const View = ({
  modalOpen,
  updateModalState,
  setPage,
  formPageNumber,
  updateFormPageNumber,
  navbarLogin,
}: ModalProps) => {
  const [backDropVisible, updateBackDropVisible] = useState(false)

  const handleClose = (_event: object, reason: string) => {
    if (reason === "backdropClick") return
    updateModalState(false)
    if (updateFormPageNumber) updateFormPageNumber(0)
  }

  const renderFormContent = () => {
    switch (formPageNumber) {
      case 2:
        return (
          <LoginForm
            updateBackDropVisible={updateBackDropVisible}
            setPage={setPage}
            updateModalState={updateModalState}
            navbarLogin={navbarLogin}
          />
        )
      case 3:
        return (
          <CreateForm
            updateBackDropVisible={updateBackDropVisible}
            setPage={setPage}
          />
        )
      default:
        return (
          <IndividualAccountForm
            modalOpen={modalOpen}
            updateModalState={updateModalState}
            setPage={setPage}
            updateFormPageNumber={updateFormPageNumber}
            updateBackDropVisible={updateBackDropVisible}
          />
        )
    }
  }

  return (
    <>
      <ModalContainer open={modalOpen} scroll={"body"} maxWidth="md">
        <FormContainer spForm={formPageNumber === 3}>
          <FormContent spForm={formPageNumber === 3}>
            <CloseButton onClick={(e) => handleClose(e, "closeButton")}>
              <img className="closeBox" src={CloseBox} alt="" />
            </CloseButton>

            <Logo
              src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/bks.svg"
              alt="Black Spectacles logo"
            />

            {renderFormContent()}
          </FormContent>
        </FormContainer>
      </ModalContainer>

      <Backdrop
        sx={{
          color: "#fff",
        }}
        style={{ zIndex: "9999" }}
        open={backDropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default View
