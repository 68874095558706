import React from "react"
import { Navigate } from "react-router-dom"

const url_string = window.location.href
const url = new URL(url_string)
const error = url.searchParams.get("error")
const error_description = url.searchParams.get("error_description")

if (
  error === "unauthorized" &&
  error_description === "Please verify your email before logging in."
)
  window.location.replace("/new_user")

const OnboardingCheckPage = () => {
  sessionStorage.clear()
  return <Navigate to="/" />
}

export default OnboardingCheckPage
