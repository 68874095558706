import React, { useCallback, useEffect } from "react"
import { DefaultRoutes } from "./routes"
import { useAuth } from "./providers/AuthProvider"
import { useAppDispatch } from "./redux/configureStore"
import { validateJWT } from "./redux/validate"
import {validateOrgDiscount} from "./redux/guest/validateOrgDiscount";

function App() {
  const auth = useAuth()
  const dispatch = useAppDispatch()

  const validate = useCallback(async () => {
    if (auth.getAuthToken()) {
      const { type } = await dispatch(validateJWT({ jwt: auth.getAuthToken() }))
      if (type === validateJWT.fulfilled.type) {
        if (!auth.isAuthenticated) {
          auth.handleSetIsAuthenticated(true)
        }
      }
    }

  }, [auth, dispatch])

  useEffect(() => {
    validate()
  }, [validate])

  return <DefaultRoutes />
}

export default App
