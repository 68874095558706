import React, { useState, useCallback } from "react"
import { Typography } from "@mui/material"
import TextField from "../TextField"
import { Button } from "../Button"
import {
  FormHeader,
  FormMessage,
  FormFieldsBox,
  FormRow,
  ServerErrorMessage,
  FormFooter,
  PasswordReset,
} from "../FormModalTemplate/styles"
import ErrorIcon from "../../images/error-icon.svg"
import {
  IndividualAccountDetails,
  createNewAccount,
} from "../../redux/individualAccount"
import * as Yup from "yup"
import update from "immutability-helper"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { useAppDispatch } from "../../redux/configureStore"
import { resetPassword } from "../../utils/resetPassword"
import { useAuth } from "../../providers/AuthProvider"

interface LabelProps {
  text: string
  required?: boolean
}

interface Props {
  updateBackDropVisible: (bool: boolean) => void
  setPage: (num: number) => void
}

const Label = ({ text, required }: LabelProps) => {
  return (
    <>
      <span>{text}</span>
      {required && <span style={{ color: "#3057E1" }}> * </span>}
    </>
  )
}

const View = ({ updateBackDropVisible, setPage }: Props) => {
  const auth = useAuth()

  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const [displayPasswordResetMessage, updateDisplayPasswordResetMessage] =
    useState(false)
  const email = useSelector(
    (state: AppState) => state.validateEmail.accountDetails?.email
  )

  const [formValues, setFormValues] = useState<IndividualAccountDetails>({
    firstName: "",
    lastName: "",
    email: email ? email : "",
    confirmEmail: "",
    password: "",
  })

  const [touched, setTouched] = useState<any>({
    firstName: false,
    lastName: false,
    password: false,
  })

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    password: "",
    serverError: "",
  })

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .required("Password is required"),
  })

  const onFieldChange = useCallback(
    (fieldName: string, value: string | boolean, selectInput?: boolean) => {
      // convert input values from string to boolean
      if (selectInput) value = value === "true" ? true : false

      setFormValues((prevValues: IndividualAccountDetails) => {
        return update(prevValues, {
          [fieldName]: {
            $set: value,
          },
        })
      })
    },
    []
  )

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
    updateDisplayPasswordResetMessage(false)
  }

  const validateFields = async (submitting?: boolean) => {
    await validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setFormErrors({
          firstName: "",
          lastName: "",
          password: "",
          serverError: "",
        })
        setIsDisabled(false)
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]:
              touched[error.path] || submitting ? error.message : "",
          }
        }, {})
        setFormErrors(errors)
        setIsDisabled(true)
      })
  }

  const handleSubmit = async () => {
    setIsDisabled(true)
    updateBackDropVisible(true)
    updateDisplayPasswordResetMessage(false)

    try {
      const { type } = await dispatch(
        createNewAccount({ individualAccountDetails: formValues })
      )

      // Fail scenario
      if (type === createNewAccount.rejected.type) {
        setFormErrors({
          ...formErrors,
          serverError: "Server error. please try again.",
        })
        setIsDisabled(false)
        updateBackDropVisible(false)
        return
      }

      auth.handleSetIsAuthenticated(true)

      // Direct user to secure checkout page
      setPage(2)
      window.scrollTo(0, 0)
    } catch (err) {
      return
    }

    updateBackDropVisible(false)
  }

  const handlePasswordReset = async () => {
    const { type } = await dispatch(resetPassword({ email: formValues.email }))

    if (type === resetPassword.rejected.type) {
      setFormErrors({
        ...formErrors,
        serverError: "Server error. please try again.",
      })
      return
    }

    updateDisplayPasswordResetMessage(true)
  }

  return (
    <>
      <FormHeader>
        <Typography variant="h3">Hey, we know you!</Typography>
      </FormHeader>

      <FormMessage>
        <Typography variant="h6">
          We recognize your Spectacular account! Please use your Spectactular
          credentials to create a Black Spectacles account.
        </Typography>
      </FormMessage>

      <FormFieldsBox>
        <form>
          <FormRow className="formRowSpacing">
            <TextField
              label={<Label text="Email" required={true} />}
              name={"email"}
              variant={"filled"}
              fullWidth={true}
              value={email}
              disabled={true}
            />
            <TextField
              label={<Label text="Password" required={true} />}
              name={"password"}
              variant={"filled"}
              type={"password"}
              fullWidth={true}
              onChange={(e) => onFieldChange("password", e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.password}
              onFocus={() => {
                handleSetTouched("password")
              }}
            />
          </FormRow>

          <FormRow className="formRowSpacing">
            <TextField
              label={<Label text="First Name" required={true} />}
              name={"firstName"}
              variant={"filled"}
              fullWidth={true}
              onChange={(e) => onFieldChange("firstName", e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.firstName}
              onFocus={() => {
                handleSetTouched("firstName")
              }}
            />
            <TextField
              label={<Label text="Last Name" required={true} />}
              name={"lastName"}
              variant={"filled"}
              fullWidth={true}
              onChange={(e) => onFieldChange("lastName", e.target.value)}
              onBlur={() => validateFields()}
              errorMessage={formErrors?.lastName}
              onFocus={() => {
                handleSetTouched("lastName")
              }}
            />
          </FormRow>

          {formErrors.serverError ? (
            <ServerErrorMessage>
              <Typography variant="body1">
                <img src={ErrorIcon} style={{ paddingRight: 8 }} alt="Error" />
                {formErrors.serverError}
              </Typography>
            </ServerErrorMessage>
          ) : null}

          <PasswordReset>
            <Typography variant="body2">
              <span onClick={handlePasswordReset}>Forgot Password?</span>
            </Typography>
          </PasswordReset>

          {displayPasswordResetMessage ? (
            <Typography variant="body2" style={{ paddingTop: 8 }}>
              <span>A password reset email has been sent</span>
            </Typography>
          ) : null}

          <FormFooter>
            <Button
              color={"primary"}
              children={"Continue to billing"}
              size={"large"}
              style={{ borderRadius: 10, width: 190 }}
              onClick={() => handleSubmit()}
              disabled={isDisabled}
            />
          </FormFooter>
        </form>
      </FormFieldsBox>
    </>
  )
}

export default View
