import styled from "styled-components"
import { Box } from "@mui/material"
import theme, { colors } from "../../styles/theme"

export const ConfirmationContainer = styled(Box)`
  width: 870px;
  background: #ffffff;
  border-radius: 3px;
  margin-top: 165px;
  margin-right: auto;
  margin-left: auto;

  ${theme.breakpoints.down("md")} {
    width: calc(100% - 64px);
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 87px;
  }
`
export const ConfirmationHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  border-bottom: 0.5px solid ${colors.gray};

  && h3 {
    font-weight: 350;
  }

  ${theme.breakpoints.down("md")} {
    height: 65px;
    padding: 0 24px;

    && h4 {
      font-size: 20px;
    }
  }

  @media (max-width: 340px) {
    && h3 {
      font-size: revert;
    }
  }
`
export const ConfirmationMessage = styled(Box)`
  padding: 40px 32px 32px 32px;

  && h4 {
    font-weight: 350;
  }

  ${theme.breakpoints.down("md")} {
    padding: 24px 32px;

    && h4 {
      font-size: 16px;
    }
  }
`

export const ConfirmationFooter = styled(Box)`
  padding: 32px;
  border-top: 1px solid ${colors.gray};
  display: flex;
  gap: 32px;
  align-items: center;

  && p {
    color: ${colors.slate};
  }

  ${theme.breakpoints.down("md")} {
    && button {
      width: 100%;
    }
  }
`
