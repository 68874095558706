import React, { useState } from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useAppDispatch } from "../../redux/configureStore"
import { getActiveSubscriptions } from "../../redux/subscription"
import { Button } from "../Button"
import { useAuth } from "../../providers/AuthProvider"

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  endpoint: string
}

const View = ({ open, setOpen, endpoint }: Props) => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(false)

  const handleEndpointRetry = async () => {
    switch (endpoint) {
      case "subscription":
        setIsDisabled(true)
        try {
          if (auth.isAuthenticated) {
            const { type } = await dispatch(getActiveSubscriptions({ auth }))

            if (type === getActiveSubscriptions.rejected.type) {
              setIsDisabled(false)
              return
            }

            setTimeout(() => {
              setOpen(false)
            }, 1000)
          }
        } catch (error) {
          return
        }
        return
      default:
        window.location.reload()
        return
    }
  }

  const handleRedirect = () => {
    window.location.href = "https://www.blackspectacles.com/pricing"
  }

  const getErrorMessage = () => {
    switch (endpoint) {
      case "subscription":
        return "We were unable to check your active subscription(s) status. Please attempt to retry or go back to the previous page."
      case "invalidToken":
        return "Your session is no longer valid. Please refresh the page."
      default:
        return "There was a network issue, please try again later."
    }
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Network service issue"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" variant="body1">
            {getErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"whiteGreyBorder"}
            children={"Go Back"}
            disabled={isDisabled}
            onClick={handleRedirect}
          ></Button>
          <Button
            color={"primary"}
            children={endpoint === "invalidToken" ? "Refresh" : "Retry"}
            disabled={isDisabled}
            onClick={() => handleEndpointRetry()}
          ></Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default View
