import React from "react"
import View from "./view"

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  endpoint: string
}

const ErrorModal = ({ open, setOpen, endpoint }: Props) => {
  return <View open={open} setOpen={setOpen} endpoint={endpoint} />
}

export default ErrorModal
