import React, { useState } from "react"
import AccountMenu from "../Menu/"
import FormModalTemplate from "../FormModalTemplate"
import { useAuth } from "../../providers/AuthProvider"

type Props = {
  setPage: (num: number) => void
}

export const CommonNavbar = ({ setPage }: Props) => {
  const auth = useAuth()

  const [open, setOpen] = useState(false)

  const handleLogout = () => {
    auth.logout()
  }

  const handleDashboardOpen = () => {
    let hostURL = `${process.env.REACT_APP_ARE_URL}`

    window.open(hostURL, "_blank")
  }

  return (
    <div id="common-navigation-main">
      <style>
        {`
      @font-face {
        font-family: 'Flama';
        font-style: normal;
        font-weight: normal;
        src: local('Flama'), url('https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/Flama+Regular.woff') format('woff');
      }
  
      #common-navigation-main *{
        margin:0;
        padding:0;
        font-size: 0.75rem;
        line-height: 1.1px;
        color: #717171;
        font-family: "Flama", sans-serif;
        font-weight: 600;
      }
  
      #common-navigation-main{
        height: 82px;
        background-color: white;
        display:flex;
        flex-wrap: nowrap;
        justify-content: center;
        border-top:#313131 4px solid;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;
        position: relative;
        z-index: 1; 
        padding: 0 80px !important;
        width: 100%;
      }

      @media only screen and (max-width: 900px) {
        #common-navigation-main {
         border-bottom: #313131 1px solid;

        }
      }
  
      #common-navigation-left{
        display:flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
      }
  
      #common-navigation-main a,
      #common-navigation-main a:hover,
      #common-navigation-main a:active,
      #common-navigation-main a:focus{
        display: block;
        text-decoration: none;
      }
  
      #common-navigation-bkslogo a{
        display:flex;
      }
  
      #common-navigation-bkslogo a:hover{
        display:flex;
      }
  
      #common-navigation-bkslogo img{
        height: 60px;
        width: 132px;
        fill:#2D2D2D;
        color: #2D2D2D;
      }
  
      #common-navigation-nav{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-left: 72px;
        gap: 32px;
      }
  
      #common-navigation-nav #common-navigation-nav-item{
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        display: block;
        justify-content: flex-start;
      }
  
      #common-navigation-nav #common-navigation-nav-item img{
        margin-top: -2px;
        margin-left: 1px;
        position: absolute;
      }
  
      #common-navigation-right{
        display: flex;
        gap: 25px;
      }

      #navigation-mobile-menu {
        display: none !important;
      }

      .common-navigation-button {
        background-color: #1377D4 !important;
        border: 1px solid #1377D4 !important;
        border-radius: 5px !important;
        color: #fff !important;
        cursor: pointer;
        display: inline-block !important;
        font-family: Flama,sans-serif !important;
        font-size: .75rem !important;
        font-weight: 700 !important;
        height: auto;
        letter-spacing: .03em;
        line-height: 1.1 !important;
        margin: 0 !important;
        padding: 15px 25px !important;
        position: relative;
        text-align: center;
        text-decoration: none !important;
        transition: all .15s linear;
        white-space: normal;
        width: auto ;
        word-break: break-word;
      }

      .common-navigation-button img{
        background-color: #E5E5E5;
        fill:#E5E5E5;
        margin-left: 3px;
      }

      .common-navigation-button-text{
        line-height: 11px;
        font-weight: 700 !important;
        color: #fff !important;
      }
      
      .common-navigation-button:hover {
        background-color: #292929 !important;
        border: 1px solid #292929 !important;
        transform: scale(1.05);
      }
  
      #common-navigation-mobile-icon {
        display:none;
      }
  
      #common-navigation-mobile-menu {
        display:none;
        visibility:hidden;
        opacity:0;
        transition: opacity 0.3s ease-in;
        position:absolute;
        top:60px;
        background-color: #ffffff;
        padding: 24px 20px;
        width: 100vw;
        box-sizing: border-box;
        margin-left: -20px;
        z-index: 70;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 7%);
      }
  
      @media only screen and (max-width: 960px) {
  
        #common-navigation-main{
          padding: 0 20px;
        }
  
        #common-navigation-nav{
          display:none;
        }
  
        #common-navigation-bkslogo img{
          height: 40px;
          width: 87px;
        }
  
        .common-navigation-button{
          height: auto;
          width: auto;
          padding: 10px 20px !important;
        }
  
        #common-navigation-mobile-icon {
          display:initial;
          margin-left: 16px;
          cursor: pointer;
        }
  
        #common-navigation-mobile-icon img{
          fill:#2d2d2d;
        }
  
        #common-navigation-mobile-menu {
          display:initial;
        }
  
        #common-navigation-mobile-menu-item{
          font-size: 14px;
          text-decoration: none;
        }
  
        #common-navigation-mobile-menu-item a{
          line-height: 11px;
        }
  
        #common-navigation-mobile-menu-item img{
          margin-top: -3px;
          margin-left: 1px;
          position: absolute;
        }
      }

      @media only screen and (max-width: 899px) {
        #common-navigation-main{
          padding: 0 32px !important;
        }
      }

      @media only screen and (max-width: 425px) {
        .common-navigation-button {
          display: none !important;
        }

        #navigation-mobile-menu {
          display: block !important;
        }
      }
  `}
      </style>

      <div
        style={{
          maxWidth: 1280,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div id="common-navigation-left">
          <section id="common-navigation-bkslogo">
            <a href="https://go.blackspectacles.com">
              <img
                src="https://dev-contextual-common-navigation.s3.us-east-2.amazonaws.com/bks.svg"
                alt="Black Spectacles logo"
              />
            </a>
          </section>
        </div>

        <div id="common-navigation-right">
          {auth.isAuthenticated ? (
            <>
              <button
                className="common-navigation-button"
                onClick={handleDashboardOpen}
              >
                <span className="common-navigation-button-text">DASHBOARD</span>
              </button>

              <button
                className="common-navigation-button"
                onClick={handleLogout}
              >
                <span className="common-navigation-button-text">LOG OUT</span>
              </button>

              <div id="navigation-mobile-menu">
                <AccountMenu loggedIn={true} />
              </div>
            </>
          ) : null}
        </div>

        {open ? (
          <FormModalTemplate
            modalOpen={open}
            updateModalState={setOpen}
            formPageNumber={2}
            setPage={setPage}
            navbarLogin={true}
          />
        ) : null}
      </div>
    </div>
  )
}
