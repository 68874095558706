import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"
import { AuthContextInterface } from "../../providers/AuthProvider"

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type UpgradePayload = {
  auth: AuthContextInterface
  activeSubId: string
  targetPriceId: string
  taxRateId: string | undefined
  coupon?: string | null
}

export const upgrade = createAsyncThunk<any, UpgradePayload>(
  "/upgrade",
  async ({ auth, activeSubId, targetPriceId, coupon, taxRateId }) => {
    const fields = {
      active_sub_id: activeSubId,
      target_price_id: targetPriceId,
      tax_rate_id: taxRateId ? taxRateId : "",
      coupon: coupon?.toLocaleUpperCase(),
    }

    return useApi(auth, "/v1/network/subscription/upgrade", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)
