import React, { useState } from "react"
import FormModalTemplate from "../FormModalTemplate"
import IndividualAccountForm from "../IndividualAccountForm"

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
}

const View = ({ modalOpen, updateModalState, setPage }: ModalProps) => {
  const [open] = useState(modalOpen)
  const [formPageNumber, updateFormPageNumber] = useState(0)

  const renderFormContent = () => {
    switch (formPageNumber) {
      case 1:
        return (
          <IndividualAccountForm
            modalOpen={open}
            updateModalState={updateModalState}
            setPage={setPage}
            updateFormPageNumber={updateFormPageNumber}
          />
        )
      default:
        return (
          <FormModalTemplate
            modalOpen={open}
            updateModalState={updateModalState}
            setPage={setPage}
            formPageNumber={formPageNumber}
            updateFormPageNumber={updateFormPageNumber}
          />
        )
    }
  }

  return renderFormContent()
}

export default View
