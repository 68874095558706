import React, { useState, useCallback } from "react"
import { Typography } from "@mui/material"
import TextField from "../TextField"
import { Button } from "../Button"
import {
  FormContainer,
  FormHeader,
  FormFieldsBox,
  FormRow,
  FieldNote,
  FormFooter,
  CloseButton,
  ServerErrorMessage,
  BlueLabel,
} from "./styles"
import CloseBox from "../../images/icons/CloseBox.png"
import * as Yup from "yup"
import update from "immutability-helper"
import { useAppDispatch } from "../../redux/configureStore"
import {
  IndividualAccountDetails,
  createNewAccount,
} from "../../redux/individualAccount"
import { handleIframeLogin } from "../../utils/iframeLogin"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import ErrorIcon from "../../images/error-icon.svg"
import { useAuth } from "../../providers/AuthProvider"
import { validateEmail } from "../../redux/guest/validateEmail"

interface LabelProps {
  text: string
  required?: boolean
}

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
  updateFormPageNumber?: (num: number) => void
  updateBackDropVisible?: (bool: boolean) => void
}

const View = ({
  modalOpen,
  updateModalState,
  setPage,
  updateFormPageNumber,
  updateBackDropVisible,
}: ModalProps) => {
  const auth = useAuth()
  const [isDisabled, setIsDisabled] = useState(true)
  const dispatch = useAppDispatch()

  const email = useSelector(
    (state: AppState) => state.validateEmail.accountDetails?.email
  )

  const [formValues, setFormValues] = useState<IndividualAccountDetails>({
    firstName: "",
    lastName: "",
    email: email ? email : "",
    confirmEmail: "",
    password: "",
  })

  const [touched, setTouched] = useState<any>({
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
    email: false,
    confirmEmail: false,
  })

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    confirmEmail: "",
    serverError: "",
  })

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$!%*?&]/,
        "Password must contain one uppercase letter, one lowerecase letter, one number, and one special character."
      )
      .min(8, "Password must be at least 8 characters.")
      .required("Password is required"),

    confirmPassword: Yup.mixed().test(
      "match",
      "Passwords do not match",
      function () {
        return this.parent.password === this.parent.confirmPassword
      }
    ),
    email: Yup.string()
      .email("Must be a valid email address.")
      .required("Email required"),
    confirmEmail: Yup.mixed()
      .test("match", "Emails do not match", function () {
        return this.parent.email === this.parent.confirmEmail
      })
      .test("email-validation", function (value) {
        return checkEmail(this, value)
      }),
  })

  const checkEmail = async (yup: any, email: string) => {
    const { payload, type } = await dispatch(validateEmail({ email }))

    if (type === validateEmail.rejected.type) {
      setFormErrors({
        ...formErrors,
        serverError: "Server error. please try again.",
      })
      return false
    }

    const { network, are } = payload.accounts
    // login form
    if ((network && are) || (!network && are)) {
      return yup.createError({ message: "Email already exists" })

      // create account form
    } else if (!network && !are) {
      return true

      // spectacular create account form
    } else {
      return yup.createError({ message: "Email already exists on Spectacular" })
    }
  }

  const onFieldChange = useCallback(
    (fieldName: string, value: string | boolean, selectInput?: boolean) => {
      // convert input values from string to boolean
      if (selectInput) value = value === "true" ? true : false

      setFormValues((prevValues: IndividualAccountDetails) => {
        return update(prevValues, {
          [fieldName]: {
            $set: value,
          },
        })
      })
    },
    []
  )

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
  }

  const validateFields = async (submitting?: boolean) => {
    await validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setFormErrors({
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          email: "",
          confirmEmail: "",
          serverError: "",
        })
        setIsDisabled(false)
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]:
              touched[error.path] || submitting ? error.message : "",
          }
        }, {})
        setFormErrors(errors)
        setIsDisabled(true)
      })
  }

  const handleClose = (_event: object, reason: string) => {
    if (reason === "backdropClick") return
    window.dataLayer.push({ event: "closeForm" })
    updateModalState(false)
  }

  const Label = ({ text, required }: LabelProps) => {
    return (
      <>
        <span>{text}</span>
        {required && <span style={{ color: "#3057E1" }}> * </span>}
      </>
    )
  }

  const handleSubmit = async () => {
    window.dataLayer.push({ event: "proceedToBilling" })

    setIsDisabled(true)
    if (updateBackDropVisible) updateBackDropVisible(true)

    const isFormValid = await validationSchema.isValid(formValues, {
      abortEarly: false, // Prevent aborting validation after first error
    })

    setTouched({
      firstName: true,
      lastName: true,
      password: true,
      confirmPassword: true,
    })

    setFormErrors({ ...formErrors, serverError: "" })

    if (!isFormValid) {
      await validateFields(true)
      setIsDisabled(true)
      return
    }

    try {
      const { type } = await dispatch(
        createNewAccount({ individualAccountDetails: formValues })
      )

      if (type === createNewAccount.rejected.type) {
        setFormErrors({
          ...formErrors,
          serverError: "Server error, please try again.",
        })

        return
      }

      auth.handleSetIsAuthenticated(true)
      handleIframeLogin()
      setPage(2)
      window.scrollTo(0, 0)
      updateModalState(false)
      if (updateBackDropVisible) updateBackDropVisible(false)
    } catch (error) {
      return
    }
  }

  const handleRedirect = () => {
    if (updateFormPageNumber) {
      switch (formErrors.confirmEmail) {
        case "Email already exists":
          updateFormPageNumber(2)
          return
        case "Email already exists on Spectacular":
          updateFormPageNumber(3)
          return
        default:
          updateFormPageNumber(2)
          return
      }
    }
  }

  return (
    <>
      <FormContainer
        open={modalOpen}
        onClose={handleClose}
        scroll={"body"}
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { maxHeight: 720 } }}
      >
        <FormHeader>
          <Typography fontSize={"22px"}>
            {/* Create An Account with {email} */}
            Step 1: Create Account
          </Typography>
          <CloseButton onClick={(e) => handleClose(e, "closeButton")}>
            <img className="closeBox" src={CloseBox} alt="" />
          </CloseButton>
        </FormHeader>

        <FormFieldsBox dividers>
          <Typography variant="body1" className="already-have-an-account">
            Already have an account?{" "}
            <BlueLabel onClick={handleRedirect}>Sign in.</BlueLabel>
          </Typography>

          <form method={"POST"}>
            <FormRow className="formRowSpacing">
              <TextField
                label={<Label text="First name" required={true} />}
                name={"firstName"}
                variant={"filled"}
                placeholder="John"
                fullWidth={true}
                onChange={(e) => onFieldChange("firstName", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.firstName}
                onFocus={() => {
                  handleSetTouched("firstName")
                }}
              />

              <TextField
                label={<Label text="Last name" required={true} />}
                name={"lastName"}
                variant={"filled"}
                placeholder="Smith"
                fullWidth={true}
                onChange={(e) => onFieldChange("lastName", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.lastName}
                onFocus={() => {
                  handleSetTouched("lastName")
                }}
              />
            </FormRow>

            <FormRow className="formRowSpacing">
              <TextField
                label={<Label text="Email" required={true} />}
                name={"email"}
                variant={"filled"}
                placeholder="John.doe@example.com"
                fullWidth={true}
                onChange={(e) => onFieldChange("email", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.email}
                onFocus={() => {
                  handleSetTouched("email")
                }}
              />

              <TextField
                label={<Label text="Confirm email" required={true} />}
                name={"confirmEmail"}
                variant={"filled"}
                placeholder="-"
                fullWidth={true}
                onChange={(e) => onFieldChange("confirmEmail", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.confirmEmail}
                onFocus={() => {
                  handleSetTouched("confirmEmail")
                }}
              />
            </FormRow>

            <FormRow className="formRowSpacing lastRow">
              <TextField
                label={<Label text="Password" required={true} />}
                name={"password"}
                type={"password"}
                variant={"filled"}
                placeholder="●●●●●●●●"
                fullWidth={true}
                onChange={(e) => onFieldChange("password", e.target.value)}
                onBlur={() => validateFields()}
                errorMessage={formErrors?.password}
                onFocus={() => {
                  handleSetTouched("password")
                }}
              />
              <TextField
                label={<Label text="Confirm password" required={true} />}
                name={"confirmPassword"}
                type={"password"}
                variant={"filled"}
                placeholder="●●●●●●●●"
                fullWidth={true}
                onChange={(e) =>
                  onFieldChange("confirmPassword", e.target.value)
                }
                onBlur={() => validateFields()}
                errorMessage={formErrors?.confirmPassword}
                onFocus={() => {
                  handleSetTouched("confirmPassword")
                }}
              />
            </FormRow>

            <FieldNote>
              <Typography variant="body2">
                Password must be at least 8 characters, contain one uppercase
                character, one lowercase character, one number, and one special
                character '!@#$%&*'.
              </Typography>
            </FieldNote>

            {formErrors.serverError ? (
              <ServerErrorMessage>
                <Typography variant="body1">
                  <img
                    src={ErrorIcon}
                    style={{ paddingRight: 8 }}
                    alt="Error"
                  />
                  {formErrors.serverError}
                </Typography>
              </ServerErrorMessage>
            ) : null}
          </form>
        </FormFieldsBox>

        <FormFooter>
          <Button
            color={"primary"}
            children={"Proceed to billing"}
            size={"large"}
            onClick={handleSubmit}
            disabled={isDisabled}
          />
        </FormFooter>
      </FormContainer>
    </>
  )
}

export default View
