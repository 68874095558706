import React from "react"
import View from "./view"

type Props = {
  checkoutComplete?: boolean
}

const CartDetails = ({ checkoutComplete }: Props) => {
  return <View checkoutComplete={checkoutComplete} />
}

export default CartDetails
