import React, { useState, useEffect } from "react"
import {
  DetailsContainer,
  DetailsHeader,
  DetailText,
  FrequencyText,
} from "./styles"
import { v4 as uuidv4 } from "uuid"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"

type Props = {
  checkoutComplete?: boolean
}

const View = ({ checkoutComplete }: Props) => {
  const [productDuration, updateProductDuration] = useState("")
  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )
  const subscriptions = useSelector(
    (state: AppState) => state.subscription?.activeSubscription
  )
  const [currentSubscription, setCurrentSubscription] = useState<any>(null)
  const [frequency, setFrequency] = useState("mo")

  const formatNumberToDollars = (num: number) => {
    num /= 100

    const numberWithCommas = num
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return `$${numberWithCommas}.00`
  }

  useEffect(() => {
    if (products && products.length > 0) {
      const product = products[0]
      const { duration } = product

      const membershipDuration = Number(duration) === 12 ? "Yearly" : "Monthly"
      updateProductDuration(membershipDuration)
    }
  }, [products])

  useEffect(() => {
    if (subscriptions?.length) {
      const subscription = subscriptions.filter((subscription) =>
        subscription.name.includes("ARE 5.0")
      )[0]

      setCurrentSubscription(subscription)
    }
  }, [subscriptions])

  useEffect(() => {
    if (products && products.length) {
      const freq = Number(products[0].duration) === 12 ? "yr" : "mo"
      setFrequency(freq)
    }
  }, [products])

  return products ? (
    // Check if current active subscription is same as cart product subscription
    // 2023 Products include "Plan" in name, use indexOf to compare
    // EX: "ARE 5.0 Expert 12-Month Subscription Plan".indexOf("ARE 5.0 Expert 12-Month Subscription")
    products[0]?.name.indexOf(currentSubscription?.name) !== -1 ? (
      <DetailsContainer>
        <DetailsHeader
          style={{
            flexDirection: "column",
            alignItems: "unset",
            justifyContent: "center",
            borderBottom:
              products[0]?.name.indexOf(currentSubscription?.name) !== -1
                ? "none"
                : "",
            marginBottom:
              products[0]?.name.indexOf(currentSubscription?.name) !== -1
                ? "0"
                : "",
            padding:
              products[0]?.name.indexOf(currentSubscription?.name) !== -1
                ? "24px"
                : "",
          }}
        >
          <Typography variant="body1">
            You are already subscribed to the{" "}
            <b>{currentSubscription?.name}.</b>
            <br />
            <br />
            Please return to the pricing page and select a different plan.
          </Typography>
        </DetailsHeader>
      </DetailsContainer>
    ) : (
      <>
        {products.map((product) => {
          const { display_name, amount } = product

          return (
            <DetailsContainer key={uuidv4()}>
              <DetailsHeader>
                <Typography variant="h3">{display_name}</Typography>
                {checkoutComplete ? null : (
                  <Typography variant="h3">
                    {formatNumberToDollars(amount)}
                    <FrequencyText>/{frequency}</FrequencyText>
                  </Typography>
                )}
              </DetailsHeader>

              <DetailText>
                <Typography variant="body1">
                  Billed {productDuration}
                </Typography>
              </DetailText>
            </DetailsContainer>
          )
        })}
      </>
    )
  ) : null
}

export default View
