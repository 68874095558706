import { AuthContextInterface } from "../providers/AuthProvider"
import { toast } from "react-toastify"

export const useApi = async (
  auth: AuthContextInterface | null,
  url: string,
  options: any = {},
  origin?: string,
) => {
  const checkStatus = async (res: Response) => {
    if (res.ok) {
      return res
    } else {
      const { error, errors } = await res.json()

      if (errors && errors.length > 0) {
        toast.error(errors[0])
        throw new Error(errors[0])
      } else if (error && error.message) {
        toast.error(errors.message)
        throw new Error(error.message)
      } else {
        toast.error(res.statusText)
        throw new Error(res.statusText)
      }
    }
  }

  options.headers = options.headers || {}
  if (options.headers && options.headers["Content-Type"] === "Automatic") {
    delete options.headers["Content-Type"]
  } else {
    options.headers["Content-Type"] = "application/json"
  }

  let baseUrl = url

  // Use services endpoint if not resetting spectacular password
  if (baseUrl.indexOf("/change_password") === -1) {
    if (origin === "ee") {
      //EE GO SERVICES ENDPOINT
      baseUrl = `${process.env.REACT_APP_EE_GO_API}${url}`
    }
    else if (origin === "bks-services") {
      baseUrl = `${process.env.REACT_APP_PLATFORM_SERVICES_API}${url}`
    }
    else {
      //NODE SERVICES ENDPOINT BY DEFAULT
      baseUrl = `${process.env.REACT_APP_PAYMENT_API_URL}${url}`
    }
  }

  if (auth) {
    const accessToken = auth.getAuthToken()

    return fetch(baseUrl, {
      ...options,
      headers: {
        // Add the Authorization header to the existing headers
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      },
    }).then(checkStatus)
  } else {
    return fetch(baseUrl, {
      ...options,
      headers: {
        ...options.headers,
      },
    }).then(checkStatus)
  }
}
