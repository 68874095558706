import React from "react"
import View from "./view"
import { Product } from "../../redux/product"

type Props = {
  products?: Product[]
  couponCode: string
  setCouponCode: (value: string) => void
  setPage: (num: number) => void
  page: number
}

const OrderSummary = ({
  products,
  couponCode,
  setCouponCode,
  setPage,
  page,
}: Props) => {
  return (
    <View
      products={products}
      couponCode={couponCode}
      setCouponCode={setCouponCode}
      setPage={setPage}
      page={page}
    />
  )
}

export default OrderSummary
