import React from "react"
import View from "./view"

type Props = {
  updateBackDropVisible: (bool: boolean) => void
  setPage: (num: number) => void
}

const CreateForm = ({ updateBackDropVisible, setPage }: Props) => {
  return (
    <View updateBackDropVisible={updateBackDropVisible} setPage={setPage} />
  )
}

export default CreateForm
