import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { PaymentMethod } from "@stripe/stripe-js"
import { useApi } from "../../hooks/useApi"
import { Product } from "../product"
import { AuthContextInterface } from "../../providers/AuthProvider"

export type PaymentDetails = {
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
  phoneNumber: string
  phoneExtension?: string
}

export type PaymentResponse = {
  cartId: string
  clientSecret: string
  subId: string
}

type Invoice = {
  invoice_id: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  paymentInvoice?: Invoice
  paymentDetailsStatus: LoadingStatuses
}
//#endregion

//#region api
type CreateSubscriptionPayload = {
  auth: AuthContextInterface
  paymentDetails: any
  products: Product[]
  coupon?: string
  taxId: string | undefined
}

export const createSubscription = createAsyncThunk<
  any,
  CreateSubscriptionPayload
>(
  "/createSubscription",
  async ({ auth, paymentDetails, products, coupon, taxId }) => {
    const filteredProducts: any = []

    products.forEach((product) =>
      filteredProducts.push({ price_id: product.id, qty: 1 })
    )

    const fields = {
      customer: {
        phone: paymentDetails.phoneNumber,
        phone_ext: paymentDetails.phoneExtension
          ? paymentDetails.phoneExtension
          : null,
        address: {
          address_id: "",
          address_1: paymentDetails.address1,
          address_2: paymentDetails.address2 ? paymentDetails.address2 : null,
          city: paymentDetails.city,
          state: paymentDetails.state,
          postal_code: paymentDetails.postalCode,
          country: paymentDetails.country,
        },
      },
      products: filteredProducts,
      coupon: coupon ? coupon.toUpperCase() : "",
      txr: taxId,
    }

    return useApi(auth, "/v1/payment/subscription", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

type PaymentSuccessPayload = {
  auth: AuthContextInterface
  cartId: string
  subId: string
  pmId: string | PaymentMethod | null
  clientSecret?: string
}

export const attachSubscription = createAsyncThunk<any, PaymentSuccessPayload>(
  "/attachSubscription",
  async ({ auth, cartId, pmId, subId, clientSecret }) => {
    const fields = {
      cart_id: cartId,
      sub_id: subId,
      pm_id: pmId,
      client_secret: clientSecret ? clientSecret : "",
    }
    return useApi(auth, "/v1/payment/success", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  paymentInvoice: undefined,
  paymentDetailsStatus: LoadingStatuses.Idle,
}

export default createSlice({
  name: "networkPayment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attachSubscription.fulfilled, (state, action) => {
      state.paymentInvoice = action.payload
    })
  },
})
//#endregion
