import React, { useEffect, useState } from "react"
import PaymentInfo from "../../components/PaymentInfo"
import { AppState, useAppDispatch } from "../../redux/configureStore"
import { getActiveSubscriptions } from "../../redux/subscription"
import { getPaymentMethods } from "../../redux/creditcard"
import { useAuth } from "../../providers/AuthProvider"
import { useSelector } from "react-redux"

const View = () => {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  // eslint-disable-next-line
  const [page, setPage] = useState(0)
  const [loadingComplete, updateLoadingComplete] = useState(false)

  const activeSubscription = useSelector(
    (state: AppState) => state?.subscription.activeSubscription
  )

  const paymentMethods = useSelector(
    (state: AppState) => state?.billingInfo.paymentMethods
  )

  let currentSubscription: any = null

  const phoneDetails = {
    phoneNumber: paymentMethods?.phone ?? "",
    phoneExtension: paymentMethods?.phone_ext ?? "",
  }

  let billingInfo = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  }

  let creditCardInfo = {
    brand: "",
    last_4: "",
    exp_month: "",
    exp_year: "",
  }

  let creditCardInfoAvailable = false

  if (
    paymentMethods?.payment_methods &&
    paymentMethods.payment_methods.length
  ) {
    const { brand, last_4, exp_month, exp_year, address } =
      paymentMethods.payment_methods[0]
    creditCardInfo = { brand, last_4, exp_month, exp_year }
    creditCardInfoAvailable = true

    if (address && Object.keys(address).length > 0) {
      const { address_1, address_2, city, state, postal_code, country } =
        address

      billingInfo = {
        address1: address_1,
        address2: address_2 ? address_2 : "",
        city,
        state,
        postalCode: postal_code,
        country,
      }
    }
  }

  document.body.style.backgroundColor = "unset"

  const getSubscriptionInfo = async () => {
    const { type } = await dispatch(getActiveSubscriptions({ auth }))
    if (type === getActiveSubscriptions.rejected.type) return
  }

  const getPaymentInfo = async () => {
    const { type } = await dispatch(getPaymentMethods({ auth }))

    if (type === getPaymentMethods.rejected.type) return
  }

  // Update subscription state when api is successful and updates store
  if (activeSubscription && activeSubscription.length > 0) {
    activeSubscription.forEach((subscription: any) => {
      if (subscription.name.indexOf("ARE 5.0") >= 0) {
        currentSubscription = subscription
      }
    })
  }

  useEffect(() => {
    if (auth.isAuthenticated) {
      getSubscriptionInfo()
      getPaymentInfo()
      updateLoadingComplete(true)
    }
    // eslint-disable-next-line
  }, [auth.isAuthenticated])

  if (creditCardInfoAvailable) {
    return (
      <PaymentInfo
        setPage={setPage}
        widget={true}
        currentSubscription={currentSubscription}
        creditCardInfoAvailable={creditCardInfoAvailable}
        creditCardInfo={creditCardInfo}
        billingInfo={billingInfo}
        phoneDetails={phoneDetails}
        getPaymentInfo={getPaymentInfo}
        pageLoadingComplete={loadingComplete}
      />
    )
  } else {
    return (
      <PaymentInfo
        setPage={setPage}
        widget={true}
        pageLoadingComplete={loadingComplete}
      />
    )
  }
}

export default View
