import React from "react"

export type Props = {
  text: string
  required?: boolean
}

const Label = ({ text, required }: Props) => {
  return (
    <>
      <span>{text}</span>
      {required && <span style={{ color: "#3057E1" }}> * </span>}
    </>
  )
}

export default Label
