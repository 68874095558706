import React from "react"
import View from "./view"

interface ModalProps {
  modalOpen: boolean
  modalDisabled: () => void
  setPage: (num: number) => void
}

const AccountForm = ({ modalOpen, modalDisabled, setPage }: ModalProps) => {
  return (
    <View
      modalOpen={modalOpen}
      modalDisabled={modalDisabled}
      setPage={setPage}
    />
  )
}

export default AccountForm
