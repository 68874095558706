import React from "react"
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { useWindowDimensions } from "../../hooks"
import theme from "../../styles/theme"
import styled from "styled-components"

import ExpandIcon from "../../images/dd-down.svg"
import ErrorIcon from "../../images/error-icon.svg"

export type Props = {
  style?: React.CSSProperties
  blank?: string | undefined
  options: Array<any>
  errorMessage?: string
  onFieldChange: (field: string, value: string) => void
  validateFields: () => void
  handleSetTouched: (field: string) => void
}

const HelperText = styled(FormHelperText)`
  color: #f44c49 !important;
  font-size: 14px !important;
  margin-bottom: -5px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 4px !important;
  margin-left: 0px !important;

  && img {
    margin-right: 7px;
    margin-top: 4px;
    align-self: flex-start;
  }
`

const Icon = () => {
  return (
    <img
      alt="expand-icon"
      src={ExpandIcon}
      style={{
        position: "absolute",
        right: "10px",
        width: "16px",
        height: "9px",
        pointerEvents: "none",
      }}
    />
  )
}

const SelectField = ({
  style,
  blank,
  options,
  errorMessage,
  onFieldChange,
  validateFields,
  handleSetTouched,
  ...props
}: Props & FormControlProps<any>) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const handleChange = (event: SelectChangeEvent) => {
    if (onFieldChange) onFieldChange(props.name, event.target.value)
  }

  const labelId = `${props.name}-label`

  return (
    <FormControl error={!!errorMessage} {...props} style={{ width: "100%" }}>
      <InputLabel
        style={{
          transform: "translate(0px, -31px) scale(1)",
          lineHeight: "22px",
          color: "revert",
        }}
        id={labelId}
      >
        {props.label}
      </InputLabel>
      <Select
        labelId={labelId}
        id={props.name}
        onChange={handleChange}
        onBlur={() => validateFields()}
        onClick={() => handleSetTouched(props.name)}
        className="selectInputField"
        style={
          style
            ? {
                ...style,
                background: errorMessage
                  ? "rgba(244, 76, 73, 0.08)"
                  : "#FAFAFA",
              }
            : {
                height: isMobile ? "56px" : "48px",
                background: errorMessage
                  ? "rgba(244, 76, 73, 0.08)"
                  : "#FAFAFA",
                fontSize: isMobile ? "14px" : "16px",
              }
        }
        IconComponent={Icon}
        value={props.value}
      >
        {props.blank && (
          <MenuItem value="" style={{ height: "48px" }}>
            <em>{props.blank}</em>
          </MenuItem>
        )}

        {options.map(({ value, label }: any) => (
          <MenuItem key={value} value={value} style={{ height: "48px" }}>
            {label}
          </MenuItem>
        ))}
      </Select>

      {!!errorMessage && (
        <HelperText>
          <img src={ErrorIcon} alt={"error"} />
          {errorMessage}
        </HelperText>
      )}
    </FormControl>
  )
}

export default SelectField
