import styled from "styled-components"
import { Box } from "@mui/material"
import theme, { colors } from "../../styles/theme"

export const SummaryContainer = styled(Box)`
  background: #ffffff;
  width: 369px;
  border-radius: 3px;

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }
`

export const SummaryHeader = styled(Box)`
  height: 64px;
  display: flex;
  align-items: center;
  margin: 0 24px;
  box-sizing: border-box;
  border-bottom: 0.5px solid ${colors.gray};

  h4 {
    display: flex;
    gap: 0;
    align-items: center;
    font-weight: 700;
    text-transform: capitalize;
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }
  }
`

export const SummaryDetailsContainer = styled(Box)`
  padding: 24px 24px 0px 24px;

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }
  }
`

export const MembershipLabel = styled(Box)`
  height: 64px;
  background-color: ${colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  && h4 {
    font-size: 18px;
    font-weight: 700;
  }

  ${theme.breakpoints.down("sm")} {
    && h4 {
      font-size: 14px;
    }
  }
`

export const PriceContainer = styled(Box)`
  padding-bottom: 32px;
  border-bottom: 0.5px solid ${colors.gray};
`

export const PriceRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 12px;

  && p {
    font-weight: 350;
    font-size: 16px;
  }

  && .seat-count {
    color: #b5b5b5;
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }
  }
`

export const CouponContainer = styled(Box)`
  padding: 32px 0;
  display: flex;
  justify-content: space-between;

  && fieldset {
    top: 0;
    border-radius: 1px;
  }

  && input {
    background-position: 90% 50% !important;
  }
`

export const FormRow = styled(Box)`
  display: flex;
  gap: 16px;
  width: 100%;

  && {
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid black;
      }
    }
  }

  && legend {
    display: none;
  }

  && button:hover * {
    border: none;
  }

  && span {
    border: 1px solid #00b8fe;
    border-radius: 3px;
  }

  ${theme.breakpoints.down("sm")} {
    gap: 20px;

    && {
      .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        height: 48px !important;
      }
    }

    && button {
      font-size: 14px !important;
    }
  }
`

export const CouponText = styled("a")`
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const Footer = styled(Box)`
  background: red;
  margin-bottom: 32px;

  && button {
    width: 100%;
  }
`
