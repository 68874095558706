import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type CouponDetails = {
  code: string
  valid: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  validCoupon?: boolean
  couponCode?: string
  status: LoadingStatuses
  error?: string
}
//#endregion

//#region api
type GetCouponPayload = {
  couponCode: string
  id?: string
}

export const checkCoupon = createAsyncThunk<any, GetCouponPayload>(
  "/checkCoupon",
  async ({ couponCode, id }) => {
    const fields = { products: [id] }

    return useApi(null, `/v1/guest/payment/coupon/${couponCode}/valid`, {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => ({
      couponCode,
      status: res.ok,
    }))
    // response does not return anything, so res.json() would cause error and make status rejected
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  validCoupon: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "coupon",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkCoupon.fulfilled, (state, action) => {
        state.validCoupon = action.payload.status
        state.couponCode = action.payload.couponCode
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(checkCoupon.rejected, (state, action) => {
        state.validCoupon = false
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion
