import styled from "styled-components"
import Box from "@mui/material/Box"
import theme, { colors } from "../../styles/theme"

export const CartContainer = styled(Box)`
  width: 870px;
  height: 100%;
  background: #fff;
  border-radius: 3px;

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }
`

export const CartHeader = styled.div`
  margin: 0 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 0.5px solid ${colors.gray};

  && h2 {
    line-height: unset;
  }

  && span {
    border: 1px solid #00b8fe;
    border-radius: 3px;
  }

  && button:hover * {
    border: none;
  }

  ${theme.breakpoints.down("sm")} {
    height: 65px;
    padding: 0 32px;

    && h4 {
      font-size: 20px;
    }
  }

  ${theme.breakpoints.down("md")} {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    && h3 {
      line-height: 1.25;
    }
  }

  @media (max-width: 405px) {
    flex-direction: column;
    gap: 16px;
    padding: 32px;
  }
`

export const ReturnText = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;

  && p {
    font-size: 16px;
    font-weight: 700 !important;
    color: #1377d4;
    display: flex;
    align-items: center;

    &:hover {
      color: #1c43cd;
    }
  }

  @media (max-width: 950px) {
    display: none;
  }

  ${theme.breakpoints.down("md")} {
    display: none;
  }
`

export const CartFooter = styled.div`
  border-top: 0.5px solid #d8d8d8;
  padding: 32px;

  ${theme.breakpoints.down("md")} {
    && button {
      width: 100%;
    }
  }
`

export const CartFooterB2B = styled.div`
  padding: 0 56px 32px;

  ${theme.breakpoints.down("md")} {
    && button {
      width: 100%;
    }
  }
`
