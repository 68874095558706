import styled from "styled-components"
import Box from "@mui/material/Box"
import theme, { colors } from "../../styles/theme"
import { FormHelperText } from "@mui/material"

export const CreditCardContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 32px;

  && span {
    border: 1px solid #00b8fe;
  }

  && button:hover * {
    border: none !important;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px 0;
  }
`

export const ButtonBox = styled(Box)`
  padding: 32px 32px 32px 0;
  border-top: 0.5px solid ${colors.gray};
  box-sizing: border-box;
  display: flex;
  gap: 16px;

  && .cancel:hover {
    color: ${colors.alert};
    border: 1px solid ${colors.alert} !important;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px;
    flex-flow: column;
  }
`
export const PaymentInfoHeader = styled(Box)`
  height: 80px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  && h3 {
    font-size: 25px;
  }

  ${theme.breakpoints.down("sm")} {
    height: 64px;
    border-bottom: 0.5px solid ${colors.gray};

    && h3 {
      font-size: 20px;
    }
  }
`

export const HelperText = styled(FormHelperText)`
  color: #f44c49 !important;
  font-size: 14px !important;
  margin-bottom: -5px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 4px !important;

  && img {
    margin-right: 7px;
    margin-top: 4px;
    align-self: flex-start;
  }
`
