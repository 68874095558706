import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { AuthContextInterface } from "../providers/AuthProvider"

//#region types
export type CreditCardInfo = {
  payment_methods: PaymentMethod[]
  phone: string
  phone_ext?: string
}

type PaymentMethod = {
  id: string
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
  address: AddressDetails
}

type AddressDetails = {
  address_1: string
  address_2: string
  city: string
  country: string
  postal_code: string
  state: string
  phone: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  paymentMethods?: CreditCardInfo
  status: LoadingStatuses
  error?: string
}
//#endregion

//#region api
type getActiveSubscriptionsPayload = {
  auth: AuthContextInterface
}

export const getPaymentMethods = createAsyncThunk<
  any,
  getActiveSubscriptionsPayload
>("/getPaymentMethods", async ({ auth }) => {
  return useApi(auth, `/v1/network/member/payment_methods`, {
    method: "GET",
  }).then((res) => res.json())
})

type getPaymentMethodPayload = {
  auth: AuthContextInterface
  subscriptionId: string
}

export const getPaymentMethod = createAsyncThunk<any, getPaymentMethodPayload>(
  "/getPaymentMethods",
  async ({ auth, subscriptionId }) => {
    return useApi(auth, `/v1/network/member/payment_method/${subscriptionId}`, {
      method: "GET",
    }).then((res) => res.json())
  }
)

type SetupIntentPayload = {
  auth: AuthContextInterface
}

export const getSetupIntent = createAsyncThunk<any, SetupIntentPayload>(
  "/getSetupIntent",
  async ({ auth }) => {
    return useApi(auth, "/v1/network/payment/setup_intent", {
      method: "POST",
    }).then((res) => res.json())
  }
)

type PaymentMethodPayload = {
  pmId: string
  auth: AuthContextInterface
}

export const updatePaymentMethod = createAsyncThunk<any, PaymentMethodPayload>(
  "/updatePaymentMethod",
  async ({ pmId, auth }) => {
    return useApi(auth, `/v1/network/member/payment_method/default/${pmId}`, {
      method: "PUT",
    }).then((res) => {
      return {
        status: res.ok,
      }
    })
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  paymentMethods: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion
