import styled from "styled-components"
import { Box } from "@mui/material"
import theme, { colors } from "../../styles/theme"

export const DetailsContainer = styled(Box)`
  margin: 0 32px;
  border-radius: 1px;

  ${theme.breakpoints.down("md")} {
    margin: 0;
    padding: 32px;
    background-clip: border-box;
    box-shadow: inset 0 0 0 32px rgba(241, 244, 249, 1) !important;
    border-top: none;
  }
`

export const DetailsHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 0 24px;

  && h3:nth-child(1) {
    font-weight: 700;
    text-transform: unset;
  }

  && h3:nth-child(2) {
    font-weight: 350;
  }

  @media (max) {
  }

  ${theme.breakpoints.down("lg")} {
    align-items: start;
    flex-direction: column;
    padding: 24px;
  }
`

export const DetailText = styled(Box)`
  padding: 0 24px 24px;
  margin: 0;
  display: flex;

  && p {
    color: #525252;
  }

  && img {
    max-height: 22px;
  }

  && .MuiSelect-select {
    padding-top: 8px;
  }

  && .required {
    color: #f96464;
  }

  && .italicize {
    font-style: italic;
    color: #838383;
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }
  }
`

export const RemoveItem = styled(Box)`
  padding: 0 24px 32px;
  margin: 8px 0 0 0;

  && p {
    color: ${colors.slate};
    width: 100px;
    cursor: pointer;
  }
`
export const FrequencyText = styled.span`
  color: ${colors.slate};
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
`

export const ButtonBox = styled(Box)`
  padding: 0 32px 32px 32px;
  box-sizing: border-box;
  display: flex;
  gap: 16px;

  && span {
    border-radius: 3px;
  }

  && button:hover * {
    border: none;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px;
    flex-flow: column;
  }
`

export const Gurantee = styled("img")`
  height: 70px;
  padding: 0 32px 24px 32px;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
    width: 100%;
  }

  &&: hover {
    cursor: pointer;
  }
`
