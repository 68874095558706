import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

export type AddressDetails = {
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
  country: string
}

export type ProductDetails = {
  id: string
  price: string
  name: string
  qty: string
}

export type TaxDetails = {
  tax: string
  txr: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  taxRate?: TaxDetails
  status: LoadingStatuses
  error?: string
}

type GetTaxRatePayload = {
  addressDetails: AddressDetails
  productDetails: ProductDetails[]
}

export const getTaxRate = createAsyncThunk<any, GetTaxRatePayload>(
  "/getTaxRate",
  async ({ addressDetails, productDetails }) => {
    const fields = { items: productDetails, address: addressDetails }

    return useApi(null, "/v1/guest/payment/tax_rate", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

const initialState: SliceState = {
  taxRate: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTaxRate.fulfilled, (state, action) => {
        state.taxRate = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(getTaxRate.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
