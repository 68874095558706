import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"
import { removeCookie, setCookie } from "../../utils/cookies"

export type LoginDetails = {
  email: string
  password: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  justLoggedIn: boolean
  status: LoadingStatuses
  error?: string
}

type LoginPayload = {
  loginCredentials: LoginDetails
}

export const login = createAsyncThunk<any, LoginPayload>(
  "/login",
  async ({ loginCredentials }) => {
    const { email, password } = loginCredentials

    const fields = {
      email: email,
      password: password,
    }

    return useApi(null, "/v1/guest/are/log_in", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

const initialState: SliceState = {
  justLoggedIn: false,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = LoadingStatuses.Loading
        state.justLoggedIn = false
        removeCookie(process.env.REACT_APP_TOKEN_NAME || "local_bks_token")
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = LoadingStatuses.Succeeded
        state.justLoggedIn = true
        setCookie(
          process.env.REACT_APP_TOKEN_NAME || "local_bks_token",
          action.payload.auth_token
        )
      })
      .addCase(login.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
        state.justLoggedIn = false
        removeCookie(process.env.REACT_APP_TOKEN_NAME || "local_bks_token")
      })
  },
})
