import React from "react"
import View from "./view"

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
}

const FormModal = ({ modalOpen, updateModalState, setPage }: ModalProps) => {
  return (
    <View
      modalOpen={modalOpen}
      updateModalState={updateModalState}
      setPage={setPage}
    />
  )
}

export default FormModal
