import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

export type ResetDetails = {
  message: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  resetPassword?: ResetDetails
  status: LoadingStatuses
  error?: string
}

type ResetPasswordPayload = {
  email?: string
}

export const resetPassword = createAsyncThunk<any, ResetPasswordPayload>(
  "/resetPassword",
  async ({ email }) => {
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!

    const fields = {
      client_id: clientId,
      email,
      connection: `${process.env.REACT_APP_AUTH0_CONNECTION}`,
    }

    return useApi(
      null,
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
      {
        method: "POST",
        body: JSON.stringify(fields),
      }
    ).then((res) => res.text())
  }
)

const initialState: SliceState = {
  resetPassword: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "reset",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPassword = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
