import React from "react"
import styled from "styled-components"
import {
  TextField as MuiTextField,
  TextFieldProps,
  FormHelperText,
} from "@mui/material"
import { useWindowDimensions } from "../../hooks"
import theme from "../../styles/theme"

import ErrorIcon from "../../images/error-icon.svg"

const HelperText = styled(FormHelperText)`
  color: #f44c49 !important;
  font-size: 14px !important;
  margin-bottom: -5px !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 4px !important;

  && img {
    margin-right: 7px;
    margin-top: 4px;
    align-self: flex-start;
  }
`

const Field = styled(MuiTextField)`
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  && {
    .MuiInputLabel-filled {
      transform: translate(12px, 14px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(12px, 5px) scale(0.7);
    }

    .MuiFormHelperText-contained {
      margin-right: 14px;
      margin-left: 0px !important;
    }

    .MuiFormHelperText-root.Mui-error {
      color: #f44c49;
      font-size: 14px;
      margin-bottom: -5px;
      font-weight: 400;
    }

    .MuiFilledInput-root.Mui-error:after {
      border-bottom-color: #f44c49;
    }

    input {
      padding: 12.5px 0 12.5px 13px;]
    }
  }
`

export type Props = TextFieldProps & {
  errorMessage?: string | null
  inputTestId?: string
  compact?: any
}

const TextField = ({
  compact,
  inputTestId,
  errorMessage,
  InputLabelProps,
  ...props
}: Props) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  return (
    <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
      <Field
        {...props}
        error={!!errorMessage}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
          style: {
            width: "100%",
            color: "#2d2d2d",
            fontSize: isMobile ? "14px" : "16px",
            marginTop: isMobile ? "4px" : "",
            transform: "translate(0px, -26px)",
            lineHeight: "22px",
          },
        }}
        InputProps={{
          style: {
            fontSize: isMobile ? "14px" : "16px",
            height: isMobile ? "56px" : "48px",
            backgroundColor: errorMessage
              ? "rgba(244, 76, 73, 0.08)"
              : "#FAFAFA",
          },
          ...props.InputProps,
        }}
      />
      {!!errorMessage && (
        <HelperText>
          <img src={ErrorIcon} alt={"error"} />
          {errorMessage}
        </HelperText>
      )}
    </div>
  )
}

export default TextField
