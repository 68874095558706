import React, { useState, useCallback } from "react"
import { Typography } from "@mui/material"
import TextField from "../TextField"
import { Button } from "../Button"
import {
  FormHeader,
  FormFieldsBox,
  FormRow,
  ServerErrorMessage,
  FormFooter,
  PasswordReset,
} from "../FormModalTemplate/styles"
import ErrorIcon from "../../images/error-icon.svg"
import * as Yup from "yup"
import update from "immutability-helper"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { useAppDispatch } from "../../redux/configureStore"
import { login } from "../../redux/network/login"
import { useAuth } from "../../providers/AuthProvider"

interface LabelProps {
  text: string
  required?: boolean
}

interface Props {
  updateBackDropVisible: (bool: boolean) => void
  setPage: (num: number) => void
  updateModalState: (bool: boolean) => void
  navbarLogin?: boolean
}

const Label = ({ text, required }: LabelProps) => {
  return (
    <>
      <span>{text}</span>
      {required && <span style={{ color: "#3057E1" }}> * </span>}
    </>
  )
}

const View = ({
  updateBackDropVisible,
  setPage,
  updateModalState,
  navbarLogin,
}: Props) => {
  const auth = useAuth()

  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const email = useSelector(
    (state: AppState) => state.validateEmail.accountDetails?.email
  )

  const [formValues, setFormValues] = useState<any>({
    email: email && navbarLogin ? "" : email,
    password: "",
  })

  const [touched, setTouched] = useState<any>({
    email: false,
    password: false,
  })

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    serverError: "",
  })

  const partialValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required"),
  })

  const onFieldChange = useCallback(
    (fieldName: string, value: string | boolean, selectInput?: boolean) => {
      // convert input values from string to boolean
      if (selectInput) value = value === "true" ? true : false

      setFormValues((prevValues: any) => {
        return update(prevValues, {
          [fieldName]: {
            $set: value,
          },
        })
      })
    },
    []
  )

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
  }

  const validateFields = async (submitting?: boolean) => {
    await partialValidationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        setFormErrors({
          email: "",
          password: "",
          serverError: "",
        })
        setIsDisabled(false)
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]:
              touched[error.path] || submitting ? error.message : "",
          }
        }, {})
        setFormErrors(errors)
        setIsDisabled(true)
      })
  }

  const handleSubmit = async () => {
    setIsDisabled(true)
    updateBackDropVisible(true)

    try {
      const { type, error }: any = await dispatch(
        login({ loginCredentials: formValues })
      )

      if (type === login.rejected.type) {
        const responseErrors = {
          credentialsError: "Email or password is incorrect.",
          serverError: "Server error, please try again.",
        }

        let errorMessage
        if (error.message.indexOf("incorrect") >= 0) {
          errorMessage = responseErrors.credentialsError
        } else {
          errorMessage = responseErrors.serverError
        }

        setFormErrors({
          ...formErrors,
          serverError: errorMessage,
        })

        updateBackDropVisible(false)
        return
      }

      auth.handleSetIsAuthenticated(true)
      updateModalState(false)
      setPage(1)
      window.scrollTo(0, 0)
      updateBackDropVisible(false)
    } catch (error) {
      return
    }
  }

  const handlePasswordReset = () => {
    let resetUrl =
      `${process.env.REACT_APP_ARE_URL}`.split("/login")[0] +
      "/profile/forgot-password"

    window.open(resetUrl, "_blank")
  }

  return (
    <>
      <FormHeader>
        <Typography fontSize={"22px"}>Welcome Back!</Typography>
      </FormHeader>

      <FormFieldsBox>
        <form>
          <FormRow className="formRowSpacing">
            <TextField
              label={<Label text="Email" required={true} />}
              name={"email"}
              variant={"filled"}
              value={formValues.email}
              fullWidth={true}
              onChange={(e) => {
                onFieldChange("email", e.target.value)
                validateFields()
              }}
              errorMessage={formErrors?.email}
              onFocus={() => {
                handleSetTouched("email")
              }}
            />
          </FormRow>

          <FormRow className="formRowSpacing">
            <TextField
              label={<Label text="Password" required={true} />}
              name={"password"}
              variant={"filled"}
              type={"password"}
              fullWidth={true}
              onChange={(e) => {
                onFieldChange("password", e.target.value)
                validateFields()
              }}
              errorMessage={formErrors?.password}
              onFocus={() => {
                handleSetTouched("password")
              }}
            />
          </FormRow>

          {formErrors.serverError ? (
            <ServerErrorMessage>
              <Typography variant="body1">
                <img src={ErrorIcon} style={{ paddingRight: 8 }} alt="Error" />
                {formErrors.serverError}
              </Typography>
            </ServerErrorMessage>
          ) : null}

          <PasswordReset>
            <Typography variant="body2">
              <span onClick={handlePasswordReset}>Forgot Password?</span>
            </Typography>
          </PasswordReset>

          <FormFooter>
            <Button
              color={"primary"}
              children={"Continue"}
              size={"large"}
              style={{ borderRadius: 10 }}
              onClick={() => handleSubmit()}
              disabled={isDisabled}
            />
          </FormFooter>
        </form>
      </FormFieldsBox>
    </>
  )
}

export default View
