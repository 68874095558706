import React from "react"
import View from "./view"

interface Props {
  setPage: (num: number) => void
  widget?: boolean
  currentSubscription?: any
  creditCardInfoAvailable?: boolean
  creditCardInfo?: CreditCardInfo
  billingInfo?: BillingInfo
  phoneDetails?: PhoneInfo
  getPaymentInfo?: () => void
  pageLoadingComplete: boolean
  couponCode?: string
  setCouponCode?: (value: string) => void
}

type CreditCardInfo = {
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
}

type BillingInfo = {
  address1: string
  city: string
  state: string
  postalCode: string
  country: string
}

type PhoneInfo = {
  phoneNumber: string
  phoneExtension: string
}

const PaymentInfo = ({
  setPage,
  widget,
  currentSubscription,
  creditCardInfoAvailable,
  creditCardInfo,
  billingInfo,
  phoneDetails,
  getPaymentInfo,
  pageLoadingComplete,
  couponCode,
  setCouponCode,
}: Props) => {
  return (
    <View
      setPage={setPage}
      widget={widget}
      currentSubscription={currentSubscription}
      creditCardInfoAvailable={creditCardInfoAvailable}
      creditCardInfo={creditCardInfo}
      billingInfo={billingInfo}
      phoneDetails={phoneDetails}
      getPaymentInfo={getPaymentInfo}
      pageLoadingComplete={pageLoadingComplete}
      couponCode={couponCode}
      setCouponCode={setCouponCode}
    />
  )
}

export default PaymentInfo
