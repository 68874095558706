import React from "react"
import View from "./view"

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
  updateFormPageNumber?: (num: number) => void
  updateBackDropVisible?: (bool: boolean) => void
}

const IndividualAccountForm = ({
  modalOpen,
  updateModalState,
  setPage,
  updateFormPageNumber,
  updateBackDropVisible,
}: ModalProps) => {
  return (
    <View
      modalOpen={modalOpen}
      updateModalState={updateModalState}
      setPage={setPage}
      updateFormPageNumber={updateFormPageNumber}
      updateBackDropVisible={updateBackDropVisible}
    />
  )
}

export default IndividualAccountForm
