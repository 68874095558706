import * as React from "react"
import { InputBaseComponentProps } from "@mui/material/InputBase"
import theme from "../../styles/theme"

const StripeInput = React.forwardRef<any, InputBaseComponentProps>(
  function StripeInput(props, ref) {
    const { component: Component, options, ...other } = props
    const [mountNode, setMountNode] = React.useState<any | null>(null)

    React.useImperativeHandle(
      ref,
      () => ({
        focus: () => mountNode.focus(),
      }),
      [mountNode]
    )

    return (
      <Component
        onReady={setMountNode}
        options={{
          placeholder: props.placeholder,
          ...options,
          style: {
            base: {
              color: theme.palette.text.primary,
              fontSize: "16px",
              fontFamily: theme.typography.fontFamily,
              "::placeholder": {
                color: "#C4C4C4",
                letterSpacing: "0.04em",
              },
            },
            invalid: {
              color: theme.palette.text.primary,
            },
          },
        }}
        {...other}
      />
    )
  }
)

export default StripeInput
