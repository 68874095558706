import { createTheme, responsiveFontSizes } from "@mui/material/styles"

export const colors = {
  black: "#2D2D2D",
  slate: "#717171",
  silver: "#F7F7F7",
  white: "#FFFFFF",
  blue: "#3057E1",
  lightBlue: "#1377D4",
  alert: "#F44C49",
  gray: "#D8D8D8",
}

let theme = createTheme({
  typography: {
    h1: {
      fontFamily: "FreightDispProBook",
      fontSize: 50,
      lineHeight: 3.4375,
      color: colors.black,
    },
    h2: {
      fontFamily: "FreightDispProBook",
      fontSize: 40,
      lineHeight: 2.8125,
      color: colors.black,
    },
    h3: {
      fontFamily: "Public Sans, sans-serif",
      fontSize: 24,
      lineHeight: 1.875,
      color: colors.black,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Public Sans",
      fontSize: 20,
      lineHeight: 1.75,
      color: colors.black,
      textTransform: "uppercase",
    },
    body1: {
      fontFamily: "Public Sans, sans-serif",
      fontSize: 16,
      color: colors.black,
      lineHeight: 1.313,
    },
    body2: {
      fontSize: 14,
      fontFamily: "Public Sans, sans-serif",
      color: colors.black,
      lineHeight: 1.25,
    },
  },
  palette: {
    primary: {
      main: colors.black,
    },
    secondary: {
      main: colors.blue,
    },
  },
})

Object.assign(theme, {
  ...theme.typography,
  ...theme.palette,
  overrides: {
    MUIRichTextEditor: {
      placeHolder: {
        paddingLeft: 10,
      },
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme
