import { Box } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import styled from "styled-components"
import theme, { colors } from "../../styles/theme"

export const ModalContainer = styled(Dialog)``

export const FormContainer = styled(({ ...props }) => <Box {...props} />)`
  max-width: ${(props) => (props.spForm ? "700px" : "464px")};
  max-height: ${(props) => (props.spForm ? "600px" : "580px")};
  padding: 32px;
  background: #f1f4f9;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.down("md")} {
    background: none;
  }
`

export const FormContent = styled(({ ...props }) => <Box {...props} />)`
  width: ${(props) => (props.spForm ? "700px" : "400px")};
  height: ${(props) => (props.spForm ? "600px" : "580px")};
  background: white;

  ${theme.breakpoints.down("md")} {
    height: 600px;
  }
`

export const Logo = styled("img")`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
`

export const FormHeader = styled(DialogTitle)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
`

export const FormMessage = styled(DialogContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0 !important;
  margin-top: 24px;
`

export const FormFieldsBox = styled(DialogContent)`
  padding: 24px 24px !important;

  && .lastRow {
    padding-bottom: 0px;
  }
`

export const FormRow = styled(({ ...props }) => <Box {...props} />)`
  display: flex;
  gap: 16px;
  padding-bottom: 20px;

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    gap: 32px;
  }
`

export const FormFooter = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 12px;

  && button {
    width: 100px;
    font-size: 16px;
    color: white;
  }

  && p {
    color: ${colors.slate};
  }

  ${theme.breakpoints.down("md")} {
    flex-direction: column;

    && button {
      width: 100% !important;
    }
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }
  }
`

export const ServerErrorMessage = styled(Box)`
  position: relative;
  bottom: 14px;

  && p {
    color: ${colors.alert};
    display: flex;
    font-size: 14px;
  }
`

export const CloseButton = styled(Box)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 31px;
  right: 32px;

  && .closeBox {
    padding: 8px;
  }

  && .closeBox:hover {
    background: #ededed;
  }

  ${theme.breakpoints.down("sm")} {
    top: 10px;
    right: 10px;
  }
`

export const PasswordReset = styled("a")`
  && span {
    color: ${colors.slate};
    cursor: pointer;
  }

  && :hover {
    color: ${colors.black};
  }
`
