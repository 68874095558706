import React from "react"
import { Box, Typography, Modal } from "@mui/material"
import { Button } from "../Button"
import {
  ConfirmationContainer,
  ConfirmationHeader,
  ConfirmationMessage,
  ConfirmationFooter,
} from "./styles"
import CartDetails from "../CartDetails"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { useAuth } from "../../providers/AuthProvider"

interface Props {
  modalOpen: boolean
}

const View = ({ modalOpen }: Props) => {
  const auth = useAuth()

  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const subscriptions = useSelector(
    (state: AppState) => state.subscription.activeSubscription
  )

  const handleClose = () => {
    // do not redirect to survey if user is upgrading
    if (subscriptions && subscriptions.length > 0) {
      window.location.href = `${process.env.REACT_APP_ARE_URL}`
      return
    }

    let redirectUrl = `${process.env.REACT_APP_ARE_URL}`.split("/login")[0]

    // Redirect to Group Management for firms
    if (products && products[0].audience === "group") {
      redirectUrl =
        `${process.env.REACT_APP_ARE_URL}`.split("/login")[0] + `/group/manage`
    }

    window.location.href = redirectUrl
  }

  return (
    <Box>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <ConfirmationContainer>
          <ConfirmationHeader>
            <Typography variant="h3">Checkout complete</Typography>
          </ConfirmationHeader>

          <ConfirmationMessage>
            <Typography variant="h4">
              A receipt will be emailed to you shortly!
            </Typography>
          </ConfirmationMessage>

          {products && products[0].audience === "individual" ? (
            <CartDetails checkoutComplete={true} />
          ) : null}

          <ConfirmationFooter>
            <Button
              color={"primary"}
              children={auth.isAuthenticated ? "Go to dashboard" : "Log in"}
              size={"large"}
              onClick={handleClose}
            />
          </ConfirmationFooter>
        </ConfirmationContainer>
      </Modal>
    </Box>
  )
}

export default View
