import { Box } from "@mui/material"
import RadioGroup from "@mui/material/RadioGroup"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import styled from "styled-components"
import theme, { colors } from "../../styles/theme"

export const FormContainer = styled(Dialog)`
  width: 870px;
  max-width: 870px;
  background: #ffffff;
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;
  background: none;

  && .MuiDialog-paperScrollBody {
    box-shadow: none;
  }

  ${theme.breakpoints.down("md")} {
    width: 100%;
  }
`

export const FormHeader = styled(DialogTitle)`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px !important;

  && h3 {
    font-weight: 350;
    hyphens: none;
  }

  #account-sign-in-top {
    text-transform: none;
    line-height: normal;
    padding-top: 8px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0 12px 0 24px !important;

    && h3 {
      font-size: 18px;
    }
  }

  @media (max-width: 350px) {
    height: 120px;

    && h3 {
      line-height: normal;
      font-size: 16px;
    }

    #account-sign-in-top {
      padding-top: 8px;
    }
  }
`

export const FormFieldsBox = styled(DialogContent)`
  margin: 0 32px;
  border-top: 0.5px solid ${colors.gray} !important;
  border-left: none !important;
  border-right: none !important;
  padding: 12px 0 0 !important;

  && .lastRow {
    padding-bottom: 0px;
  }

  && .already-have-an-account {
    padding-bottom: 24px;
  }

  ${theme.breakpoints.down("sm")} {
    margin: 0;
    padding: 24px 20px !important;

    && .formRowSpacing:first-child {
      margin-top: 32px;
    }

    && .formRowSpacing:not(:first-child) {
      margin-top: 0px;
    }

    && .formRowSpacing:first-child > div:nth-child(2),
    .formRowSpacing:not(:first-child) > div {
      margin-top: 30px;
    }

    && .formRowSpacing > div {
      padding-bottom: 20px;
    }

    && .lastRow > div:nth-child(2) {
      padding-bottom: 0px;
    }
  }
`

export const FormRow = styled(({ ...props }) => <Box {...props} />)`
  display: flex;
  gap: 16px;
  padding-bottom: 20px;

  ${theme.breakpoints.down("sm")} {
    gap: 20px;
    flex-flow: wrap;
    padding-bottom: 0px;
  }
`

export const FieldNote = styled(Box)`
  font-style: italic;

  && p {
    color: #979797;
    margin-top: 16px;
    margin-bottom: 40px;
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
`

export const BlueBox = styled(Box)`
  background-color: ${colors.lightBlue};
  padding: 24px;
  margin-top: 32px;

  && p {
    margin-top: -4px;
    margin-bottom: -1px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 20px;
    margin-top: 24px;

    && p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`
export const FormFooter = styled(Box)`
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;

  && button {
    width: 197px;
    fontsize: 16px;
    color: white;
  }

  && p {
    color: ${colors.slate};
  }

  ${theme.breakpoints.down("md")} {
    flex-direction: column;

    && button {
      width: 100%;
    }
  }

  ${theme.breakpoints.down("sm")} {
    && p {
      font-size: 14px;
    }

    #account-sign-in-bottom {
      display: none;
    }
  }
`

export const BlueLabel = styled.span`
  color: #1377d4;
  font-weight: 700;
  cursor: pointer;
`

export const CloseButton = styled(Box)`
  cursor: pointer;
  width: 32px;
  height: 32px;

  && .closeBox {
    padding: 8px;
  }

  && .closeBox:hover {{
    background: #EDEDED;
  }
`

export const RadioButtonsContainer = styled(RadioGroup)`
  display: flex;
  gap: 16px;

  ${theme.breakpoints.down("sm")} {
    gap 20px;

    && label:nth-child(2) {
      margin-top: 0;
    }
  }
`

export const ServerErrorMessage = styled(Box)`
  position: relative;
  bottom: 16px;

  && p {
    color: ${colors.alert};
  }
`
