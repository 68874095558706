import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"

export type AccountDetails = {
  email: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  accountDetails?: AccountDetails
  status: LoadingStatuses
  error?: string
}

type validateEmailPayload = {
  email: string
}

export const validateEmail = createAsyncThunk<any, validateEmailPayload>(
  "/validateEmail",
  async ({ email }) => {
    const fields = {
      email,
    }

    return useApi(null, "/v1/guest/are/member/email", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then(async (res) => {
      const accounts = await res.json()

      return {
        email,
        accounts,
      }
    })
  }
)

const initialState: SliceState = {
  accountDetails: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "validateEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateEmail.fulfilled, (state, action) => {
        state.accountDetails = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(validateEmail.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
