import React from "react"
import View from "./view"

type Props = {
  page: number
  setPage: (num: number) => void
}

const CheckoutPage = ({ page, setPage }: Props) => {
  return <View page={page} setPage={setPage} />
}

export default CheckoutPage
