import React from "react"
import View from "./view"

interface ModalProps {
  modalOpen: boolean
  updateModalState: (open: boolean) => void
  setPage: (num: number) => void
  formPageNumber: number
  updateFormPageNumber?: (num: number) => void
  navbarLogin?: boolean
}

const FormModalTemplate = ({
  modalOpen,
  updateModalState,
  setPage,
  formPageNumber,
  updateFormPageNumber,
  navbarLogin,
}: ModalProps) => {
  return (
    <View
      modalOpen={modalOpen}
      updateModalState={updateModalState}
      setPage={setPage}
      formPageNumber={formPageNumber}
      updateFormPageNumber={updateFormPageNumber}
      navbarLogin={navbarLogin}
    />
  )
}

export default FormModalTemplate
