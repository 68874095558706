import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { AuthContextInterface } from "../providers/AuthProvider"

//#region types
export type SubscriptionInfo = {
  subscriptions: Subscription[]
}

export type Subscription = {
  id: string
  name: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  activeSubscription?: Subscription[]
  status: LoadingStatuses
  error?: string
}
//#endregion

//#region api
type ActiveSubScriptionPayload = {
  auth: AuthContextInterface
}

export const getActiveSubscriptions = createAsyncThunk<
  any,
  ActiveSubScriptionPayload
>("/getActiveSubscriptions", async ({ auth }) => {
  return useApi(auth, "/v1/network/member/active_subscriptions", {
    method: "GET",
  }).then((res) => res.json())
})
//#endregion

//#region slice
const initialState: SliceState = {
  activeSubscription: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "activeSubscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveSubscriptions.fulfilled, (state, action) => {
        state.activeSubscription = action.payload.subscriptions
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(getActiveSubscriptions.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
        console.error(action.error.message)
      })
  },
})
//#endregion
