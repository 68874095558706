import React from "react"
import View from "./view"

type Props = {
  updateBackDropVisible: (bool: boolean) => void
  setPage: (num: number) => void
  updateModalState: (bool: boolean) => void
  navbarLogin?: boolean
}

const LoginForm = ({
  updateBackDropVisible,
  setPage,
  updateModalState,
  navbarLogin,
}: Props) => {
  return (
    <View
      updateBackDropVisible={updateBackDropVisible}
      setPage={setPage}
      updateModalState={updateModalState}
      navbarLogin={navbarLogin}
    />
  )
}

export default LoginForm
