import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"
import { setCookie } from "../utils/cookies"

export type IndividualAccountDetails = {
  firstName: string
  lastName: string
  email: string
  confirmEmail: string
  password: string
}

export type Member = {
  memberId?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  individualAccountDetails?: IndividualAccountDetails
  member?: Member
  slug?: string
  status: LoadingStatuses
  error?: string
}

type CreateAccountPayload = {
  individualAccountDetails: IndividualAccountDetails
}

export const createNewAccount = createAsyncThunk<any, CreateAccountPayload>(
  "/createAccount",
  async ({ individualAccountDetails }) => {
    const { firstName, lastName, email, password } = individualAccountDetails

    const fields = {
      email: email,
      password: password,
      firstname: firstName,
      lastname: lastName,
      property_id: 1,
    }

    return useApi(null, "/v1/guest/are/member", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

const initialState: SliceState = {
  individualAccountDetails: undefined,
  member: undefined,
  slug: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewAccount.fulfilled, (state, action) => {
        state.individualAccountDetails = action.payload
        state.member = { memberId: action.payload.member.id }
        state.slug = action.payload.org_slug
        state.status = LoadingStatuses.Succeeded
        setCookie(
          process.env.REACT_APP_TOKEN_NAME || "local_bks_token",
          action.payload.member.auth_token
        )
      })
      .addCase(createNewAccount.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
