import React, {
  PropsWithChildren,
  ButtonHTMLAttributes,
  useState,
  ReactNode,
} from "react"
import { Button as MUIButton } from "@mui/material"
import { getStyles, getHoverClass } from "./buttonStyles"
import { useWindowDimensions } from "../../hooks"
import theme from "../../styles/theme"

interface Props extends ButtonHTMLAttributes<{}> {
  size?: "small" | "medium" | "large"
  onClick?: React.MouseEventHandler<{}>
  variant?: "text" | "outlined" | "contained"
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "default"
    | "white"
    | "lightGrey"
    | "lightBlue"
    | "whiteGreyBorder"
    | "red"
  hoverColor?:
    | "default"
    | "whiteRedBorder"
    | "mediumGrey"
    | "whiteGreyBorder"
    | "primaryHover"
    | "secondaryHover"
    | "lightBlueHover"
  hoverChildren?: ReactNode
}

export const Button: React.FC<PropsWithChildren<Props>> = ({
  type,
  size,
  style,
  color,
  onClick,
  variant,
  children,
  hoverColor,
  hoverChildren,
  ...rest
}) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= theme.breakpoints.values.sm

  const [hover, setHover] = useState(false)

  const displayHoverColor = hoverColor ? hoverColor : getHoverClass(color)

  const buttonStyles = getStyles(
    hover ? displayHoverColor : color,
    rest.disabled
  )

  let styles = {
    fontFamily: "Flama",
    borderRadius: "3px",
    textTransform: "uppercase",
    minWidth: "120px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontSize: "14px",
    letterSpacing: 0,
    ...buttonStyles,
    ...style,
  }

  if (size === "medium") {
    styles = { ...styles, height: "40px" }
  } else if (size === "large") {
    styles = { ...styles, height: "48px", fontSize: isMobile ? "14px" : "14px" }
  }

  const handleClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    if (onClick) onClick(e)
  }

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseleave = () => {
    setHover(false)
  }

  return (
    <MUIButton
      {...rest}
      size={size}
      type={type}
      style={styles}
      variant={variant}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseleave}
    >
      {hover && hoverChildren ? <>{hoverChildren}</> : <>{children}</>}
    </MUIButton>
  )
}
