import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type CreditCardInfo = {
  payment_methods: PaymentMethod[]
  phone: string
  phone_ext?: string
}

type PaymentMethod = {
  id: string
  brand: string
  last_4: string
  exp_month: string
  exp_year: string
  address?: AddressDetails
}

type AddressDetails = {
  address_1: string
  address_2: string
  city: string
  country: string
  postal_code: string
  state: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}
//#endregion

//#region api
type getActiveSubscriptionsPayload = {
  id?: string
  pmId: string | any
}

export const validatePaymentMethod = createAsyncThunk<
  any,
  getActiveSubscriptionsPayload
>("/validatePaymentMethod", async ({ pmId }) => {
  return useApi(null, `/v1/guest/payment/payment_method/${pmId}`, {
    method: "GET",
  }).then((res) => res.json())
})

//#region slice
//#endregion
