import { configureStore } from "@reduxjs/toolkit"
import { useDispatch } from "react-redux"
import rootReducer, { RootState } from "./rootReducer"
import logger from "redux-logger"

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
})

export type AppDispatch = typeof store.dispatch
export type AppState = RootState
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
