import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

export type CartDetails = {
  items: Item[]
  tax: number
  total: number
  address: AddressDetails
  discount: number
  sub_total: number
  tax_rate_id?: string
}

export type Item = {
  id: string
  price: string
  qty: string
  name: string
}

export type AddressDetails = {
  address1?: string
  city?: string
  state?: string
  postal_code?: string
  country?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  cartDetails?: CartDetails
  status: LoadingStatuses
  error?: string
}

type UpdatePricingPayload = {
  items: Item[]
  coupon?: string
  address?: AddressDetails
}

export const updatePrice = createAsyncThunk<any, UpdatePricingPayload>(
  "/reprice",
  async ({ items, coupon, address }) => {
    const fields = {
      items,
      coupon: coupon ? coupon?.toUpperCase() : "",
      address,
      no_cache: true,
    }

    return useApi(null, "/v1/guest/payment/cart/reprice", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then(async (res) => {
      const response = await res.json()
      const data = { ...response, address }
      return data
    })
  }
)

const initialState: SliceState = {
  cartDetails: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "reprice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePrice.fulfilled, (state, action) => {
        state.cartDetails = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(updatePrice.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
