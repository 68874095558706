export const getStyles = (type?: string, disabled?: boolean) => {
  if (disabled) return disabledStyle
  if (type === "white") return white
  else if (type === "whiteGreyBorder") return whiteGreyBorder
  else if (type === "lightGrey") return lightGrey
  else if (type === "mediumGrey") return mediumGrey
  else if (type === "secondary") return secondary
  else if (type === "secondaryHover") return secondaryHover
  else if (type === "primary") return primary
  else if (type === "primaryHover") return primaryHover
  else if (type === "lightBlue") return lightBlue
  else if (type === "lightBlueHover") return lightBlueHover
  else if (type === "red") return red
  else if (type === "redHover") return redHover

  return { border: "1px solid black" }
}

export const getHoverClass = (type?: string) => {
  if (type === "white") return "whiteGreyBorder"
  else if (type === "whiteGreyBorder") return "white"
  else if (type === "lightGrey") return "mediumGrey"
  else if (type === "secondary") return "secondaryHover"
  else if (type === "primary") return "primaryHover"
  else if (type === "lightBlue") return "lightBlueHover"
  else if (type === "red") return "redHover"
}

const red: any = {
  background: "#F44C49",
  color: "#fff",
  fontWeight: 700,
}

const redHover: any = {
  background: "#E8403D",
  color: "#fff",
  fontWeight: 700,
}

const white: any = {
  background: "white",
  border: "1px solid #2D2D2D",
  fontWeight: 700,
}

const whiteGreyBorder: any = {
  background: "white",
  border: "1px solid #D8D8D8",
  color: "#979797",
  fontWeight: 700,
}

const lightGrey: any = {
  color: "#979797",
  backgroundColor: "#f7f7f7",
  fontWeight: 700,
}

const mediumGrey: any = {
  color: "#979797",
  backgroundColor: "#eaeaea",
  fontWeight: 700,
}

const secondary: any = {
  color: "#1377d4",
  border: 0,
  fontWeight: 700,
}

const secondaryHover: any = {
  color: "#FFFFFF",
  backgroundColor: "#2d2d2d",
  border: 0,
  fontWeight: 700,
}

const primary: any = {
  color: "#FFFFFF",
  backgroundColor: "#1377D4",
  border: 0,
  fontWeight: 700,
}

const primaryHover: any = {
  color: "#FFFFFF",
  backgroundColor: "#2d2d2d",
  border: 0,
  fontWeight: 700,
}

const lightBlue: any = {
  color: "#2D2D2D",
  backgroundColor: "#EBEFFC",
  fontWeight: 700,
}

const lightBlueHover: any = {
  color: "#2D2D2D",
  backgroundColor: "#DDE3F8",
  fontWeight: 700,
}

const disabledStyle: any = {
  color: "#B5B5B5",
  background: "#F6F6F6",
  border: "1px solid #B5B5B5",
}
