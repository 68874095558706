import { Box } from "@mui/material"
import styled from "styled-components"
import theme from "../../styles/theme"

export const MainContentContainer = styled(Box)`
  display: flex;
  gap: 40px;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 80px;
  justify-content: center;

  ${theme.breakpoints.down("md")} {
    flex-direction: column;
    padding: 0;
    margin-top: 0;
  }
`

export const SideContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.down("md")} {
    margin: 0 32px;
  }
`
