import { useLocation } from "react-router-dom"


const ORG_QUERY_KEY = "o";

export const useOrgDiscount = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    if(queryParams.get(ORG_QUERY_KEY)){
      sessionStorage.setItem(ORG_QUERY_KEY, queryParams.get(ORG_QUERY_KEY) ?? "")
    }

    const isOrgCheckout = (): boolean => {
      return !!sessionStorage.getItem(ORG_QUERY_KEY);
    }

    const orgId = () => sessionStorage.getItem(ORG_QUERY_KEY) as unknown as number

    return {
        isOrgCheckout,
        orgId,
    }
}
