import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { PaymentMethod } from "@stripe/stripe-js"
import { useApi } from "../hooks/useApi"
import { Product } from "./product"
import { AuthContextInterface } from "../providers/AuthProvider"

//#region types
export type Member = {
  memberId?: string
}

export type PaymentDetails = {
  cardName?: string
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: string
  phoneNumber: string
  phoneExtension?: string
  saveCreditCard?: boolean
  token?: string
  products?: Product[]
  member?: Member
}

export type PaymentResponse = {
  cartId: string
  clientSecret: string
  subId: string
}

type Invoice = {
  invoice_id: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  paymentDetails?: PaymentDetails
  paymentResponse?: PaymentResponse
  paymentInvoice?: Invoice
  paymentDetailsStatus: LoadingStatuses
}
//#endregion

//#region api
type CreateNewPaymentPayload = {
  auth: AuthContextInterface
  paymentDetails: PaymentDetails
  products: Product[]
  coupon?: string
  taxId: string
}
export const createNewPayment = createAsyncThunk<any, CreateNewPaymentPayload>(
  "/createNewPayment",
  async ({ auth, paymentDetails, products, coupon, taxId }) => {
    const filteredProducts: any = []

    products.forEach((product) =>
      filteredProducts.push({ price_id: product.id })
    )

    const fields = {
      customer: {
        phone: paymentDetails.phoneNumber,
        phone_ext: paymentDetails.phoneExtension
          ? paymentDetails.phoneExtension
          : null,
        address: {
          address_1: paymentDetails.address1,
          address_2: paymentDetails.address2 ? paymentDetails.address2 : null,
          city: paymentDetails.city,
          state: paymentDetails.state,
          postal_code: paymentDetails.postalCode,
          country: paymentDetails.country,
        },
      },
      products: filteredProducts,
      // Stripe is case sensitive so coupons must be converted uppercase before sending to api
      coupon: coupon?.toUpperCase(),
      txr: taxId,
    }

    return useApi(auth, "/v1/payment/subscription", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

type PaymentSuccessPayload = {
  auth: AuthContextInterface
  cartId: string
  subId: string
  pmId: string | PaymentMethod | null
  clientSecret?: string
}
export const notifySuccessPayment = createAsyncThunk<
  any,
  PaymentSuccessPayload
>(
  "/notifySuccessPayment",
  async ({ auth, cartId, pmId, subId, clientSecret }) => {
    const fields = {
      cart_id: cartId,
      sub_id: subId,
      pm_id: pmId,
      client_secret: clientSecret ? clientSecret : "",
    }
    return useApi(auth, "/v1/payment/success", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  paymentDetails: undefined,
  paymentResponse: undefined,
  paymentInvoice: undefined,
  paymentDetailsStatus: LoadingStatuses.Idle,
}

export default createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewPayment.fulfilled, (state, action) => {
      const cartId = action.payload.cart_id
      const subId = action.payload.items[0].subscription_id
      const clientSecret = action.payload.items[0].client_secret

      state.paymentResponse = {
        cartId,
        subId,
        clientSecret,
      }
    })
    builder.addCase(notifySuccessPayment.fulfilled, (state, action) => {
      state.paymentInvoice = action.payload
    })
  },
})
//#endregion
