import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../../hooks/useApi"
import { AuthContextInterface } from "../../providers/AuthProvider"

export type CartProrateDetails = {
  items: Item[]
  sub_total: number
  tax: number
  tax_rate: number
  tax_rate_id?: string
  discount: number
  proration: number
  credit: number
  total: number
}

export type Item = {
  id: string
  coupon?: string
  coupon_type: string
  original_price: number
  discount: number
  proration: number
}

export type AddressDetails = {
  address1?: string
  address2?: string
  city?: string
  state?: string
  postal_code?: string
  country?: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  cartProrateDetails?: CartProrateDetails
  status: LoadingStatuses
  error?: string
}

type UpdatePricingPayload = {
  auth: AuthContextInterface
  items: Item[]
  coupon?: string | undefined
  address?: AddressDetails
}

export const getProration = createAsyncThunk<any, UpdatePricingPayload>(
  "/prorate",
  async ({ auth, items, coupon, address }) => {
    const fields = {
      items,
      coupon: coupon ? coupon.toUpperCase() : "",
      address,
      no_cache: true,
    }

    return useApi(auth, "/v1/network/cart/reprice", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => res.json())
  }
)

const initialState: SliceState = {
  cartProrateDetails: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "prorate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProration.fulfilled, (state, action) => {
        state.cartProrateDetails = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(getProration.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
