import React, { useState, createContext, useContext } from "react"
import { getCookie, removeCookie } from "../../utils/cookies"

export interface AuthContextInterface {
  isAuthenticated: boolean
  getAuthToken(): string
  handleSetIsAuthenticated(value: boolean): void
  logout(): void
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const handleSetIsAuthenticated = (value: boolean) => {
    setIsAuthenticated(value)
  }

  const getAuthToken = () => {
    return getCookie(process.env.REACT_APP_TOKEN_NAME || "local_bks_token")
  }

  const logout = () => {
    setIsAuthenticated(false)
    removeCookie(process.env.REACT_APP_TOKEN_NAME || "local_bks_token")

    window.location.href = process.env.REACT_APP_ARE_URL!
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getAuthToken,
        handleSetIsAuthenticated,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
