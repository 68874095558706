import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type JWTDetails = {
  jwt: string
  valid: boolean
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

type SliceState = {
  validJWT?: boolean
  status: LoadingStatuses
  error?: string
}
//#endregion

//#region api
type GetJWTPayload = {
  jwt: string
}

export const validateJWT = createAsyncThunk<any, GetJWTPayload>(
  "/validate",
  async ({ jwt }) => {
    const fields = { jwt }

    return useApi(null, `/jwt/validate`, {
      method: "POST",
      body: JSON.stringify(fields),
    }).then((res) => ({
      status: res.ok,
    }))
    // response does not return anything, so res.json() would cause error and make status rejected
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  validJWT: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "jwt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateJWT.fulfilled, (state, action) => {
        state.validJWT = action.payload.status
        state.status = LoadingStatuses.Succeeded
        state.error = undefined
      })
      .addCase(validateJWT.rejected, (state, action) => {
        state.validJWT = false
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion
