import React from "react"
import View from "./view"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!)

interface Props {
  isAREProduct?: boolean
  updateBillingForm?: boolean
  widget?: boolean
  setPage?: (num: number) => void
  updateCardFormVisible?: (val: boolean) => void
  getPaymentInfo?: () => void
  couponCode?: string
  setCouponCode?: (value: string) => void
}

const SecureCheckout = ({
  isAREProduct,
  updateBillingForm,
  widget,
  setPage,
  updateCardFormVisible,
  getPaymentInfo,
  couponCode,
  setCouponCode,
}: Props) => {
  const products = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const member = useSelector((state: AppState) => state.account.member)

  return (
    <Elements stripe={stripePromise}>
      <View
        products={products}
        member={member}
        isAREProduct={isAREProduct}
        widget={widget}
        setPage={setPage}
        updateBillingForm={updateBillingForm}
        updateCardFormVisible={updateCardFormVisible}
        getPaymentInfo={getPaymentInfo}
        couponCode={couponCode}
        setCouponCode={setCouponCode}
      />
    </Elements>
  )
}

export default SecureCheckout
