import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region types
export type Product = {
  id: string
  name: string
  active: boolean
  amount: number
  type: string
  duration?: number
  site: string
  display_name: string
  audience: string
  product_details: string
  access_level: string
  cap_limit: string
}

export enum LoadingStatuses {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

export type CartProducts = {
  products?: Product[]
}

type SliceState = {
  cartProducts?: CartProducts
  status: LoadingStatuses
  error: string | null | undefined
}
//#endregion

const compare = (a: any, b: any) => {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

//#region api
type GetProductsPayload = {
  productIds: string[]
}
export const getProducts = createAsyncThunk<any, GetProductsPayload>(
  "/getProducts",
  async ({ productIds }) => {
    const fields = {
      products: productIds,
    }

    return useApi(null, "/v1/guest/payment/products", {
      method: "POST",
      body: JSON.stringify(fields),
    }).then(async (res) => {
      const results = await res.json()
      const products = results?.products

      // sort products order in store to adjust display order
      if (products.length > 1) {
        products.sort((a: any, b: any) => compare(a, b))
      }

      return results
    })
  }
)
//#endregion

//#region slice
const initialState: SliceState = {
  cartProducts: undefined,
  status: LoadingStatuses.Idle,
  error: undefined,
}

export default createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.cartProducts = action.payload
        state.status = LoadingStatuses.Succeeded
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = LoadingStatuses.Failed
        state.error = action.error.message
      })
  },
})
//#endregion
