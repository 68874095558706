import React from "react"
import View from "./view"

interface Props {
  modalOpen: boolean
}

const ConfirmationModal = ({ modalOpen }: Props) => {
  return <View modalOpen={modalOpen} />
}

export default ConfirmationModal
